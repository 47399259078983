import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';

import './../Resources/css/block_locationAndContact.css';

import { Row, Col, Container } from 'reactstrap';
import Block from '../../../Controls/Scafolding/Block';

import imgMap from '../Resources/Images/locations16.png';
import imgEntity from '../Resources/Images/contact-entity.svg';
import imgPhone from '../Resources/Images/contact-phone.svg';
import imgMail from '../Resources/Images/contact-mail.svg';
import imgEmail from '../Resources/Images/contact-email.svg';
import imgLinkedIn from '../Resources/Images/contact-linkedin.svg';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';

const ContactItem = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Row className="contact-item">
            <Col lg="3" className="contact-item-image">
                <img src={props.logo} className="img-fluid" />
            </Col>
            <Col lg="9">
                <div className="contact-item-header">
                    {props.header}
                </div>
                <div className="contact-item-text">
                    {props.anchor && <a className="contact-item-text-anchor" href={props.anchor}>{props.text}</a>}
                    {!props.anchor && props.text}<br />
                    {props.text2 && props.text2}
                </div>
            </Col>
        </Row>
    );
}

const LocationAndContact = (props) => {

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="locationsAndContact" isAlt={false}>

            <Row>
                <Col lg="6">
                    <Container id="locations">

                        <TextHeader text={
                            <Fragment>
                                locations
                            </Fragment>
                        } />

                        <Row>
                            <Col lg="12" className="contact-header">
                                Birdsmouth PM services the Sydney metropolitan and regional areas of NSW to provide the same <span className="highlight">strength</span> - <span className="highlight">integrity </span> - <span className="highlight">support</span> to each project.
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12" className="contact-image">
                                <img src={imgMap} className="img-fluid" />
                            </Col>
                        </Row>

                    </Container>
                </Col>
                <Col lg="6">
                    <Container id="contact">

                        <TextHeader text={
                            <Fragment>
                                contact
                            </Fragment>
                        } />

                        <ContactItem
                            logo={imgEntity}
                            header="entity"
                            text="Birdsmouth PM Pty Ltd"
                            text2="ABN: 11 639 291 991" />

                        <ContactItem
                            logo={imgPhone}
                            header="phone"
                            text="+61 (0) 405 315 311" />

                        <ContactItem
                            logo={imgMail}
                            header="email"
                            text="michael@birdsmouthpm.com.au" />

                        <ContactItem
                            logo={imgEmail}
                            header="mail"
                            text="po box 1071 double bay nsw 1360" />

                        <ContactItem
                            logo={imgLinkedIn}
                            header="linkedin"
                            text="Michael Kondilios"
                            anchor="https://www.linkedin.com/in/michael-k-5b6ab276/" />

                    </Container>

                </Col>

            </Row>

        </Block>
    );
}
export default LocationAndContact;














