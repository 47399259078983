import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSign, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';

export const ContentWrapper = props =>
    <div className="content-wrapper">
        {props.children}
    </div>;

export const ContentPrizes = props =>
    <Fragment>
        <Row>
            <Col lg={{ size: 11, offset: 1 }} xs={{ size: 12 }} className="text-center main-price">
                the Kingfish Cup <b>First prize</b>
                <br />Rob Allen GT 1.2 Timberline Rollergun fitted with a reel
                <br />the Kingfish Cup <b>Ladies Champion</b>
                <br />McComb Spearguns 1100 Laminated timber speargun
            </Col>
        </Row>
        <Row>
            <Col lg={{ size: 5, offset: 1 }} xs={{ size:12 }}>
                <ul>
                    <li>Shark Shield Freedom 7 </li>
                    <li>Ocean Guardian eSPEAR </li>
                    <li><b>2 Pairs of </b>Penetrator Composite Blades</li>
                    <li><b>4 x </b>DiveR fish cooler bags</li>
                    <li>2 x SharkBanz Zepelin</li>
                    <li>2 x SharkBanz (black)</li>
                    <li>Collins & Co Apparel packs</li>
                </ul>
            </Col>
            <Col lg={{ size: 5, offset: 1 }} xs={{ size: 12 }}>
                <ul>
                    <li>Suunto D4f Computer </li>
                    <li>Rob Allen Sparid Evo </li>
                    <li>Mannysub Roller Muzzle </li>
                    <li>3/2mm Adreno Tuna Pro Wetsuit </li>
                    <li>Adreno Voucher (<b>$250.99</b>)</li>
                    <li>Adreno Voucher (<b>$150.99</b>)</li>
                </ul>
            </Col>
        </Row>
    </Fragment>;


export const ContentPrizesFinal = props =>
    <Fragment>
        <Row>
            <Col lg={{ size: 11, offset: 1 }} xs={{ size: 12 }} className="text-center main-price">
                <b>Kingfish Cup First Open Prizes</b>
                <br /><b>Champion</b> Engraved Rob Allen GT 1.2 Timberline Rollergun fitted with a reel
                <br /><b>Runner Up</b> Shark Shield Freedom 7
                <br /><b>3rd Place</b> Sunnto D4f Computer
                <br /><b>5th </b> 3/2mm Adreno Tuna Pro Wetsuit
                <br /><b>6th </b> DiveR fish esky bag
                <br /><b>7th </b> Mannysub Double Roller Muzzle
                <br /><b>8th </b> Ocean Guardian eSPEAR
                <br /><b>9th </b> Shark Banz Black
                <br /><b>10th </b> Shark Banz Black
                <br /><b>11th </b> Shark Banz Zeppelin
                <br /><b>12th </b> Shark Banz Zeppelin
            </Col>
        </Row>
        <Row>
            <Col lg={{ size: 5, offset: 1 }} xs={{ size: 12 }} className="text-center main-price">
                <b>Kingfish Cup Women Prizes</b>
                <br /><b>Champion</b> McComb 1100 Speargun
                <br /><b>Runner Up</b> Spearo Womens 3.5mm 2 piece wetsuit
                <br /><b>3rd Place</b> Penetrator Ghost fins
            </Col>
            <Col lg={{ size: 5, offset: 1 }} xs={{ size: 12 }} className="text-center main-price">
                <b>Kingfish Juniors Prizes</b>
                <br /><b>Champion</b> RA 3.5mm open cell wetsuit
                <br /><b>Runner Up</b> DiveR fish esky bag
                <br /><b>3rd Place</b> Adreno $150 gift voucher
            </Col>
        </Row>
       
    </Fragment>;

export const ContentRules0 = props =>
    <Fragment>
        <p>
            <b>Kingfish Cup Regulations</b>
        </p>
        <p>
            This is a fun, easy event to take part of – go diving, spear a kingish, weigh it in at a weigh station and be in the chance to get a prize no matter the size of the legal kingfish.
        </p>
        <p>
            However, there are some guidelines that need to be adhered to so we have a smooth running event to occur.
        </p>
    </Fragment>;

export const ContentRules1 = props =>
    <Fragment>
        <Row>
            <Col lg={{ size: 11, offset: 1 }} xs={{ size: 12 }}>
                <ul>
                    <li><span className="content-highlight">Fishing Boundaries</span> - Fish can ONLY be taken between Bird Island and Bass Point (fishing outside this area you will be disqualified.)</li>
                    <li><span className="content-highlight">Signing on daily for the event</span> - letting us know you are going to dive on that day.
                        Once you have registered online for the Kingfish Cup (ensuring you have entered your mobile number and email in your registration).  On the days you choose to dive you will still need to let us know when you are entering the water, and  approximately where you will be, and how long you expect to be diving for.  This process is the “sign on”
                        You will need to sign on <a href="https://adrenosydneykingfishcup.com.au/SignOn">https://adrenosydneykingfishcup.com.au/SignOn</a> (<b>the green button</b>) your intent to dive on each of the days that you choose to fish (you may fish either one, or both, of the days of the Kingfish Cup.)  You may dive multiple  locations, just please remember and this is important to “sign off”
                    </li>
                    <li><span className="content-highlight">Signing off for the day</span> - letting us know you are safely back.
                        You need to let us know you are back on land so we do not contact services to go looking for you and to save you from being disqualified!
                        <a href="https://adrenosydneykingfishcup.com.au/SignOff">https://adrenosydneykingfishcup.com.au/SignOff  </a>  (<b>the red button</b>), if you do not sign off by 16:00 PM – you will be disqualified by the committee, and emergency services called to locate you. Please press the “RED” button to sign off! Log back on to the website to sign on
                    </li>
                    <li>
                        <p>
                            <span className="content-highlight">Weighing in your Kingfish</span> -
                            Your Kingfish must be weighed-in at one of the nominated non contact weigh-in stations.
                        </p>
                        <ul>
                            <li>Location 1: <span className="content-highlight">Adreno Sydney Store - 1 / 678-682 Botany Rd, Alexandria </span>  <br />Opens 10 – 5 pm</li>
                            <li>Location 2: <span className="content-highlight">Norah Head Sporties – Victoria St, Norah Head  </span> <br />Opens 1 – 5 pm</li>
                            <li>Location 3: <span className="content-highlight">Terrigal - Boat Ramp </span> <br />Opens 1 – 5 pm</li>
                            <li>Location 4: <span className="content-highlight">Little Manly –  Boat ramp - Craig Ave Manly </span>  <br />Opens 1 – 5 pm</li>
                            <li>Location 5: <span className="content-highlight">Wollongong Area - TBC</span> <br />Opens 1 – 5 pm ( if you plan to fish out of Wollongong and would like to weigh in there – please contact the phone number on this website, there is a USFA Wollongong member who will weigh your fish in for you between 1-5pm on each day.)</li>
                        </ul>
                    </li>
                </ul>
            </Col>
        </Row>
    </Fragment>;


export const ContentRules2 = props =>
    <Fragment>
        <Row>
            <Col lg={{ size: 11, offset:1 }} xs={{ size: 12 }}>
                <p>
                    <b>A more detailed of rules</b> can be found at the <b><a href="https://usfa.org.au/askc-rules/" target="_blank">USFA Website</a></b>
                </p>
            </Col>
        </Row>
        
    </Fragment>;

export const ContentRules3 = props =>
    <Fragment>
        <Row>
            <Col lg={{ size: 12 }} xs={{ size: 12 }}>
                <span class="content-highlight">Other Stuff</span> - This is ethical stuff and it comes down to your own integrity, like it was mentioned above – this is a fun, easy event to be a part of, don’t complicate things with doubt please.
            </Col>
        </Row>
        <Row>
            <Col lg={{ size: 6, offset: 0 }} xs={{ size: 12 }}>
                <ul>
                    <li>You are allowed to enter one kingfish per day over the weekend</li>
                    <li>Float and Alpha flag must be used by the diver</li>
                    <li>Fish landed by the one diver is to be weighed in by that diver only, no one else shall weigh in another diver’s fish for themselves.</li>
                    <li>Do not enter the water before 0800</li>
                    <li>Sign on each day</li>
                    <li>Sign off each day before 1600</li>
                    <li>No line fishing from boat or shore</li>
                    <li>Dive only in the permitted areas as per NSW Fisheries regulations.</li>
                    <li>You must comply with all current NSW health orders</li>
                </ul>
            </Col>
            <Col lg={{ size: 6, offset: 0 }} xs={{ size: 12 }}>
                <ul>
                    <li>Any issues that may arise the organisers will revert to the Sydney Zone Spearfishing event rules for guidance.</li>
                    <li>Prizes are for Open, Ladies and Junior categories, and, their will be 3 Mystery Weight prizes, and, prizes for attending the presentation evening at Adreno.</li>
                    <li>The “OPEN” category means everyone. The Ladies, self-explanatory. Juniors under 18 years of age on the date of each days event.</li>
                    <li>Mystery Weight shall be determined by a non-competitor drawing a weight “out of a hat” at the presentation evening. The three closest to the mystery weight shall receive the prizes.  The Open category heaviest fish will not be included in the Mystery Weight, nor will the Lucky Door entrant major prize winner.</li>
                </ul>
            </Col>
        </Row>
    </Fragment>;

export const ContentRules4 = props =>
    <Fragment>
        <Row>
            <Col>
                <p>
                    <b>PRIZES</b>
                </p>
            </Col>
        </Row>
        <Row>
            <Col lg={{ size: 11, offset: 1 }} xs={{ size: 12 }}>
                <ul>
                    <li><span className="content-highlight">Heaviest Kingfish Opens </span> - 1st to 15th place</li>
                    <li><span className="content-highlight">Ladies</span> - 1st to 5th place</li>
                    <li><span className="content-highlight">Juniors</span> - 1st to 5th place</li>
                    <li><span className="content-highlight">Rockhoppers Category</span></li>
                    <li><span className="content-highlight">Mystery Weights </span> - Kingfish weight closest to the weight drawn</li>
                    <li><span className="content-highlight">Mystery Ticket prizes</span></li>
                </ul>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    Mystery Weight shall be determined by a non-competitor drawing a weight “out of a hat” at the presentation evening. The three closest to the mystery weight shall receive the prizes.  The Open category heaviest fish will not be included in the Mystery Weight, nor will the Lucky Door entrant major prize winner.
                </p>
            </Col>
        </Row>
    </Fragment>;

export const ContentRegister0 = props =>
    <Fragment>
        <p>
            The <b>Adreno Sydney Kingish Cup</b> is a tournament operated <b>the USFA</b> <i>the Underwater Skindivers and Fisherman Association</i>
        </p>
        <p>
            The USFA represents all spearfishers in NSW. We insist that all stakeholders are able to share the marine resource; spearfishers should not be locked out of spatial areas; spearfishers have safe access to diving locations; spearfishers are allowed to dive shallow water reefs and headlands. The USFA  has always, and will continue to promote Safe diving and Selective and Sustainable spearfishing practices.
        </p>
        <p>
            Benefits of Joining the USFA
        </p>
    </Fragment>;

export const ContentRegister1 = props =>
    <Fragment>
        <Row>
            <Col lg={{ size: 11, offset: 1 }} xs={{ size: 12 }}>
                <ul>
                    <li><span className="content-highlight">Tournament</span> – The clubs of the USFA organise both monthly zone (Alliman & Taylor shields) and state tournaments such as the  Spearfest, Sydney & Canada Cup, South Coast, Far South Coast, Eden 3-Way championships and the State Titles to name a few. We also have initiated a host of Social based tournaments such as the One Up One Down, Kingfish Cup, Selfie Comp, Junior Cup and our Exceptional Capture Awards. These programs cater for those who want a more social level of involvement without entering the traditional tournaments mentioned above.</li>
                    <li><span className="content-highlight">Insurance</span> – As a member of the USFA you are covered by a sporting injuries insurance policy which covers you in the event of an accident. This policy is the most comprehensive ever for spearfishermen. It does not cover you just in tournament and is valid all over the world! This coverage alone is worth joining for! Please visit our Insurance page for specific details and extensive benefits.</li>
                    <li><span className="content-highlight">USFA website</span> – Through the website & social media the USFA makes extensive use of the internet to keep members & supporters informed of legislative developments, media releases & general matters of interest as well as upcoming events.</li>
                    <li><span className="content-highlight">Youth development & Diving Safety Education</span> – the USFA is committed to diver education programs and the nurturing of our youth to participate in our sport in the safest manner possible.</li>
                    <li><span className="content-highlight">Independents</span> – Independent divers are encouraged to join and have a representative to stand for any issues or suggestions they may have.</li>
                </ul>
            </Col>
        </Row>
    </Fragment>;

export const ContentRegister2 = props =>
    <Fragment>
        <Row>
            <Col lg={{ size: 11, offset: 1 }} xs={{ size: 12 }}>
                <p>
                    In order to participate in the <b>2024 Adreno Sydney Kingfish Cup</b> you need to be an <b>USFA</b> member and then pay the <b>$20 Kingfish Cup Membership</b>
                </p>
                <p>
                    If you are not already a USFA member you can get a <b>$110</b> year membership (which will give you automatic <b>10% discount at Adreno</b>) or a temporary membership for the weekend of the tournament  for <b>$20</b>
                </p>
                <p>
                    To Join <b>the USFA</b> or to register for the <b>Adreno Sydney Kingfish Cup</b> Visit the <b><Link to="/Register">Register Page</Link></b>
                </p>
            </Col>
        </Row>
    </Fragment>;

export const ContentRegister3 = props =>
    <Fragment>
        <Row>
            <Col lg={{ size: 11, offset: 1 }} xs={{ size: 12 }}>
                <p>
                    On the Cup Days; you need to <b>Sign On</b> & <b>Sign Off</b> before entering the water and when getting out
                </p>
                <p>
                    Use the Links below to <b>ign On & Sign Off</b> (you can Sign On & Off multiple times if getting in and out of the water differnet times during the day and/or weekend).
                </p>
            </Col>
        </Row>
        <Row>
            <Col lg={{ size: 11, offset: 1 }} xs={{ size: 12 }}>
                <p>
                    For any issues reach out to <b>Simon</b> on 0439 818 409 
                </p>
            </Col>
        </Row>
        <Row>
            <Col lg={{ size:11, offset:1 }} className="text-center">
                <Link className="btn btn-success" to="/SignOn"><FontAwesomeIcon icon="facebook" />Sign On</Link>&nbsp;
                <Link className="btn btn-danger" to="/SignOff">Sign Off</Link>
            </Col>
        </Row>
    </Fragment>;






