import React, { Fragment } from 'react';

export const ContentCourseIntroduction = () => 
    <Fragment>
        <p className="course-item-header h5 text-success text-uppercase font-weight-bold">
            Introduction to Skindiving
        </p>

        <p className="course-item-duration text-success">
            4 hours course
        </p>

        <p className="course-item-cost">
            <b>$300</b> 1 diver
        </p>

        <p className="course-item-cost">
            <b>$450</b> if you bring a buddy (for both on Weekdays)
        </p>

        <p className="course-item-intro text-muted">
            The Introduction to Skindiving is ideal for someone wishing to experience what is below the sea and how to access this world safely...
        </p>
    </Fragment>;

export const ContentCourseIntroductionExtended = () =>
    <Fragment>

        <ContentCourseIntroduction />

        <p className="course-item-intro text-muted">
            The Introduction to Skindiving is ideal for someone wishing to experience what is below the sea and how to access this world safely. Our aim here is to set you up on a path for you to enjoy the experience of breath hold diving. Not a spearfishing session, though a speargun can be arranged upon request.
        </p>

        <p className="course-item-intro text-muted">
            The course content includes:
        </p>
        <ul className="text-muted">
            <li>Discussing basic physiology, breathing properly, the equipment required</li>
            <li>Practical techniques of Buoyancy, Finning, Diving, Breath hold, and Equalising</li>
            <li>Exploring the underwater world</li>
            <li>Problem solving and avoiding/minimising the risk of injury.</li>
        </ul>
    </Fragment>;


export const ContentCourseLearning = () =>
    <Fragment>
        <p className="course-item-header h5 text-success text-uppercase font-weight-bold">
            Learning the way
        </p>

        <p className="course-item-duration text-success">
            6 hours course
        </p>

        <p className="course-item-cost">
            <b>$400</b> 1 diver
        </p>

        <p className="course-item-cost">
            <b>$650</b> if you bring a buddy (for both)
        </p>

        <p className="course-item-intro text-muted">
            A one-day private spearfishing theory and skills workshop for the spearfisher who has begun spearfishing,
            perhaps...
        </p>

    </Fragment>;

export const ContentCourseLearningExtended = () =>
    <Fragment>

        <ContentCourseLearning />

        <p className="course-item-intro text-muted">
            A one-day private spearfishing theory and skills workshop for the spearfisher who has begun spearfishing, perhaps has done a freediving course and now wishes to understand the how’s and why’s of hunting fish and diving safely.
        </p>

        <p className="course-item-intro text-muted">
            You need to be comfortable in the water to consider this course.
            A bespoke session customized from what goals you have, as well as what we observe and feel needs working on.
        </p>

        <p className="course-item-intro text-muted">
            The course content includes:
        </p>
        <ul className="text-muted">
            <li>Buoyancy</li>
            <li>Streamlining of dive technique</li>
            <li>Mental aspect and how to get your head around “it”</li>
            <li>Attaining a comfortable bottom time for you</li>
            <li>Breath hold technique</li>
            <li>Why relaxation is important and tricks to help you relax</li>
            <li>Equalization</li>
            <li>Safety techniques and procedures of rescuing</li>
            <li>Avoiding/minimizing the risk of injuries.</li>
            <li>Fish species behavior and approach</li>
            <li>Diving with and using a speargun efficiently</li>
            <li>Cleaning your catch</li>
        </ul>
    </Fragment>;


export const ContentCourse2DayWorkshop = () =>
    <Fragment>
        <p className="course-item-header h5 text-success text-uppercase font-weight-bold">
            Spearfishing 2-day workshop
        </p>

        <p className="course-item-duration text-success">
            8 hours per day
        </p>

        <p className="course-item-cost">
            <b>$550</b> per diver
        </p>

        <p className="course-item-intro text-muted">
            designed to give participants a solid knowledge of the aspects of Spearfishing. All levels of spearfisher will benefit from these two days.
        </p>

    </Fragment>;

export const ContentCourse2DayWorkshopExtended = () =>
    <Fragment>

        <ContentCourse2DayWorkshop />

        <p className="course-item-intro text-muted" >
            All levels of spearfisher will benefit from these two days.
        </p>

        <p className="course-item-intro text-muted" >
            The course content includes:
        </p>
        <ul className="text-muted">
            <li>Physiology</li>
            <li>Breathing Relaxing Equalisation</li>
            <li>Discussion on safe spearfishing</li>
            <li>Improving your skill set through correct technique and mental approach</li>
            <li>Speargun handling and management</li>
            <li>Spearfishing ethics and code of conduct</li>
            <li>Spearfishing! Hunting skills</li>
            <li>Fish ID</li>
            <li>Safety techniques and procedures of rescuing</li>
            <li>Training plans</li>
            <li>Problem solving and avoiding/minimising risk of injury</li>
        </ul>
    </Fragment>;


export const ContentCourseHunter = () =>
    <Fragment>
        <p className="course-item-header h5 text-success text-uppercase font-weight-bold">
            Spearfishing hunter
        </p>

        <p className="course-item-duration text-success">
            4 hours course
        </p>

        <p className="course-item-cost">
            <b>$300</b> 1 diver
        </p>

        <p className="course-item-cost">
            <b>$470</b> if you bring a buddy (for both)
        </p>

        <p className="course-item-intro text-muted" >
            A bespoke spearfishing skills class for the adept diver who wishes to further develop their diving, breath hold and underwater hunting skills
        </p>

    </Fragment>;

export const ContentCourseHunterExtended = () =>
    <Fragment>

        <ContentCourseHunter />

        <p className="course-item-intro text-muted" >
            The course content includes:
        </p>
        <ul className="text-muted">
            <li>Buoyancy</li>
            <li>Streamlining of dive technique</li>
            <li>Mental aspect and how to get your head around “it”</li>
            <li>Attaining comfortable bottom time</li>
            <li>Proper breathing before and after the dive</li>
            <li>Breath hold technique</li>
            <li>Why relaxation is important</li>
            <li>Equalisation</li>
            <li>Safety techniques and procedures of rescuing</li>
            <li>Solving expected/unexpected problems</li>
            <li>Avoiding/minimising the risk of injuries.</li>
            <li>Fish specie behaviour and approach</li>
            <li>Diving with a speargun efficiently</li>
            <li>Cleaning your catch</li>
        </ul>
    </Fragment>;


export const ContentCourseDiveKids = () =>
    <Fragment>
        <p className="course-item-header h5 text-success text-uppercase font-weight-bold">
            Dive with the kids
        </p>

        <p className="course-item-duration text-success">
            4 hours course
        </p>

        <p className="course-item-cost">
            <b>$300</b> Parent and Child
        </p>

        <p className="course-item-cost">
            <b>$300</b> Child only
        </p>

        <p className="course-item-cost">
            <b>$100</b> Each additionaly parent / child <small><i>4 max</i></small>
        </p>

        <p className="course-item-intro text-muted" >
            This experience gives the parent some good insights into assisting their kids, and the young ones a better understanding of what can occur on and below the water and being aware of their environment...
        </p>

    </Fragment>;

export const ContentCourseDiveKidsExtended = () =>
    <Fragment>

        <ContentCourseDiveKids />

        <p className="course-item-intro text-muted" >
            The course content includes:
        </p>
        <ul className="text-muted">
            <li>Discussing basic physiology, proper breathing, the dive kit</li>
            <li>Practical techniques of Buoyancy, Finning, Diving, Breath hold, and Equalising</li>
            <li>Solving expected/unexpected problems.</li>
            <li>Avoiding/minimising the risk of injuries.</li>
        </ul>

    </Fragment>;



