import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Media } from 'reactstrap';

import './../Resources/css/block_top.css'

import Common from '../../../Shared/Common';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

import { ContentPaellaTheLot, ContentPaellaSeafoodOnly, ContentPaellaSpecial } from './_ContentBlocks'
import { ContentPaellaVeg } from './_ContentBlocks';

import imgVeg from './../Resources/Images/_Veg.png'
import imgLot from './../Resources/Images/_LotNew.jpg'
import imgSeafood from './../Resources/Images/_Seafood.jpg'
import imgSpecial1 from './../Resources/Images/_special1.jpg'
import imgSpecial2 from './../Resources/Images/_special2.jpg'
import Block from '../../../Controls/Scafolding/Block';

const OurPaellas = (props) => {

    useEffect(() => {
    });

    return (
        <Block name="ourPaellas" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    <strong>O</strong>ur <strong>P</strong>aellas
                </Fragment>
            } />

            <Row>

                <Col lg="6">
                    <ContentPaellaTheLot />
                </Col>

                <Col lg="6">
                    <img src={imgLot} className="img-fluid img-border-light img-square" />
                </Col>

            </Row>

            <Row>

                <Col lg="6" className="text-center d-none d-lg-block">
                    <img src={imgVeg} className="img-fluid img-border-light img-square" />
                </Col>

                <Col lg="6">
                    <ContentPaellaVeg />
                </Col>

                <Col lg="6" className="text-center d-lg-none">
                    <img src={imgVeg} className="img-fluid img-border-light img-square" />
                </Col>

            </Row>

            <Row>

                <Col lg="6">
                    <ContentPaellaSeafoodOnly />
                </Col>

                <Col lg="6">
                    <img src={imgSeafood} className="img-fluid img-border-light img-square" />
                </Col>

            </Row>


            <Row>
                <Col lg="6">
                    <img src={imgSpecial1} className="img-fluid img-border-light img-square" />
                </Col>

                <Col lg="6">
                    <ContentPaellaSpecial />
                </Col>

            </Row>

        </Block>
    );
};

export default OurPaellas;
