import React, { Fragment, useEffect, useState } from 'react';
import './../Resources/css/Top.css'

import { Row, Col } from 'reactstrap';
import { UncontrolledCarousel } from 'reactstrap';

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

const Top = (props) => {
    const [blockName, setBlockName] = useState("");
    const [images, setImages] = useState([]);

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);

        setImages(props.images);
    }, [props.images]);

    return (
        <Block name="top" isAlt={true} isFluid={true}>
            <UncontrolledCarousel items={images} Fluid />
        </Block>
    );
};

export default Top;
