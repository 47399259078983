import React, { useEffect, useState } from "react";
import Common from "../../Shared/Common";

import { Container, Row, Col } from 'reactstrap';

const Block = (props) => {
    const [name, setName] = useState("");
    const [isFluid] = useState();
    const [addClass, setAddClass] = useState("");
    const [containerClass, setContainerClass] = useState("");

    useEffect(() => {
        setName(props.name);
        Common.traceInit("Block " + name);

        let background = props.isAlt ? "darkBkg" : "whiteBkg";
        setAddClass("block " + props.name + " " + background);
        setContainerClass(props.isFluid ? "container-fluid" : "");

    },[name, addClass]);

    return (
        <div id={name} className={addClass}>
                <Row>
                    <Col>
                        <Container fluid={props.isFluid}>
                            {props.children}
                        </Container>
                    </Col>
                </Row>
        </div>
    );
};

export default Block;
