import React, { Component } from 'react';
import './Resources/css/site.css';
import 'react-notifications/lib/notifications.css';

import pkLogo from './Resources/Images/PKLogo.png';

import { NavMenu } from '../../Controls/NavMenu';
import Common from '../../Shared/Common';
import { NotificationContainer } from 'react-notifications';

import Top from './Blocks/Top';
import WhyUs from './Blocks/WhyUs';
import Events from './Blocks/Events';
import OurPaellas from './Blocks/OurPaellas';
import Pricing from './Blocks/Pricing';
import ContactUs from './Blocks/ContactUs';
import Block from '../../Controls/Scafolding/Block';
import { TextHeader } from '../../Controls/Digital/TextHeaders';
import { Gallery } from '../../Controls/Digital/Gallery';
import AppWrapper from '../../Controls/Scafolding/AppWrapper';

export class PaellaKing extends Component {

    constructor(props) {
        super(props);
        Common.traceInit('Component Init -> /PKStart');

        let logoIconPath = require("./Resources/Images/PKLogo.ico");

        this.state = {
            brandCode: 'PK',
            menu: {
                hasLogo: false,
                logoUrl: '',
                styles: {},

                text: 'Sydney Paella King',
                textSmallDisplay: 'Sydney Paella King',
                icon: logoIconPath,

                items: [
                    { linkLabel: 'Home', linkUrl: '#top' },
                    { linkLabel: 'WhyUs', linkUrl: '#whyUs' },
                    { linkLabel: 'Events', linkUrl: '#events' },
                    { linkLabel: 'Our Paellas', linkUrl: '#ourPaellas' },
                    { linkLabel: 'Pricing', linkUrl: '#price' },
                    { linkLabel: 'Contact', linkUrl: '#contactUs' },
                    { linkLabel: 'Photo Gallery', linkUrl: '#gallery' }
                ],
            },
            carouselImages: [],
            galleryImagesLeft: [],
            galleryImagesRight: [],
        };
    }

    componentDidMount() {
        var loadedImgsCarousel = Common.ImportAllImagesInFolder(require.context('./Resources/Images/Carousel', false));
        var loadedImgsGalleyLeft = Common.ImportAllImagesInFolder(require.context('./Resources/Images/GalleryLeft', false));
        var loadedImgsGalleyRight = Common.ImportAllImagesInFolder(require.context('./Resources/Images/GalleryRight', false));

        Common.log("ImgsCarousel Loaded");
        Common.log(loadedImgsCarousel);

        Common.log("loadedImgsGalleyLeft Loaded");
        Common.log(loadedImgsGalleyLeft);

        Common.log("loadedImgsGalleyRight Loaded");
        Common.log(loadedImgsGalleyRight);

        this.setState({
            carouselImages: loadedImgsCarousel,
            galleryImagesLeft: loadedImgsGalleyLeft,
            galleryImagesRight: loadedImgsGalleyRight
        });
    }

    render() {
        return (
            <AppWrapper wrapClass="pk">
                <NavMenu menu={this.state.menu} addClass="Menu sticky-top" />
                <Top />
                <WhyUs />
                <Events images={this.state.carouselImages} />
                <OurPaellas />
                <Pricing />
                <ContactUs />

                <Block name="gallery" isAlt={true}>

                    <TextHeader text="from our best parties..." />

                    <Gallery
                        imagesLeft={this.state.galleryImagesLeft}
                        imagesRight={this.state.galleryImagesRight}
                        placeholderSrc={pkLogo} />

                </Block>
                <NotificationContainer />

            </AppWrapper>
        );
    }
}
