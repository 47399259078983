import React, { Fragment, useEffect, useState } from 'react';

import { Row, Col, Container, FormGroup, Input, Button } from 'reactstrap';

import './../Resources/css/ContactUs.css'
import Common from '../../../Shared/Common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faFish, faHandPointRight, faMobile } from '@fortawesome/free-solid-svg-icons';

import CommonAPI from '../../../Shared/CommonAPI';
import Block from '../../../Controls/Scafolding/Block';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

const ContactUs = (props) => {

    const [blockName, setBlockName] = useState("");
    const [model, setModel] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    const handleChange = e => {
        setModel({ ...model, [e.target.name]: e.target.value });
        Common.log("Changed Field {0} to {1}", [e.target.name, e.target.value]);
    }

    const sendMessage = () => {
        Common.trace("send message");
        Common.log(model);

        CommonAPI.sendMessage(
            model,
            null,
            {
                successMessage: "Thanks for your message; we will be in touch soon!!!",
                errorMessage: "An error Ocurred; please use the provided email or phone number to reach us"
            });
    }

    return (
        <Block name="contactUs" isAlt="true">

            <TextHeader text={
                <Fragment>
                    <strong>C</strong>ontact 
                    <strong>A</strong>ustralia 
                    <strong>S</strong>pearfishing 
                    <strong>A</strong>cademy <FontAwesomeIcon icon={faFish} />
                </Fragment>
            } />

            <TextTitle text={
                <Fragment>
                    {/*<p>Reach out or Use the form below - (Include at least one contact method)</p>*/}
                    {/*<p>Or simply you can reach us at</p>*/}
                    <p>Email or Call us!!</p>
                    <ul className="no-bullets">
                        <li><FontAwesomeIcon icon={faEnvelopeOpen} /> <b><a href="mailto:info.SpearfishingAcademy@gmail.com">info.SpearfishingAcademy@gmail.com</a></b> </li>
                        <li><FontAwesomeIcon icon={faMobile} /> <b>+61 439 818 409</b></li>
                    </ul>
                </Fragment>
            } />

            {/*<Row>*/}
            {/*    <Col lg="4">*/}
            {/*        <Container>*/}
            {/*            <Row>*/}
            {/*                <Col lg="12">*/}
            {/*                    <FormGroup>*/}
            {/*                        <Input type="text" placeholder="First Name / Last Name" name="name" value={model.name} onChange={handleChange} />*/}
            {/*                    </FormGroup>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Row>*/}
            {/*                <Col lg="12">*/}
            {/*                    <FormGroup>*/}
            {/*                        <Input type="text" placeholder="Email" name="email" value={model.email} onChange={handleChange} />*/}
            {/*                    </FormGroup>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Row>*/}
            {/*                <Col lg="12">*/}
            {/*                    <FormGroup>*/}
            {/*                        <Input type="text" placeholder="Phone Number" name="phone" value={model.phone} onChange={handleChange} type="" />*/}
            {/*                    </FormGroup>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Container>*/}
            {/*    </Col>*/}

            {/*    <Col lg="8">*/}
            {/*        <Container>*/}
            {/*            <Row>*/}
            {/*                <Col lg="12">*/}
            {/*                    <FormGroup>*/}
            {/*                        <textarea className="form-control" name="message" name="message" placeholder="Message" value={model.message} onChange={handleChange} rows="4" />*/}
            {/*                    </FormGroup>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Row>*/}
            {/*                <Col lg="12">*/}
            {/*                    <FormGroup>*/}
            {/*                        <Button className="form-control" color="success" onClick={sendMessage}>*/}
            {/*                            <FontAwesomeIcon icon={faHandPointRight} /> Send Message*/}
            {/*                        </Button>*/}
            {/*                    </FormGroup>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Container>*/}
            {/*    </Col>*/}

            {/*</Row>*/}

        </Block>
    );
};

export default ContactUs;
