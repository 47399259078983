import React, { Fragment, useEffect, useState } from 'react';
import Block from '../../../Controls/Scafolding/Block';
import Common from '../../../Shared/Common';

import './../Resources/css/block_projects.css'

import { Row, Col, Container, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFilter, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {  Modal, ModalBody } from 'reactstrap';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';
import CommonAPI from '../../../Shared/CommonAPI';

const Projects = (props) => {

    const [blockName, setBlockName] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState([]);
    const [projects, setProjects] = useState([]);

    let getJobsCallback = (data) => {
        Common.log(data);
        setProjects(data);
    };

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);

        CommonAPI.getJobs({}, getJobsCallback);
    }, [setProjects]);

    const toggle = () => {
        setOpen(!open);
    }

    const openPopUp = (project) => {
        Common.log("open Project pop up");
        Common.log(project);

        setOpen(!open);
        setSelectedProject(project);
    }

    const closePopUp = () => {
        setOpen(false);
    }

    return (
        <Block name="projects" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    projects
                </Fragment>
            } />

            <TextTitle text={
                <Fragment>
                    <p className="">
                        Michael has been involved in delivering the following projects
                    </p>
                </Fragment>
            } />

            <Row>
                {projects.map((project, index) =>
                    <Col lg="4" className="" key={index} >

                        <div className="projectPopUp">
                            <a onClick={openPopUp.bind(this, project)}>
                                <div className="projectPopUp-img">
                                    <img width="282"
                                        height="282"
                                        src={project.imagePath}
                                        sizes="(max-width: 322px) calc(100vw - 40px), 282px" />
                                </div>

                                <div className="projectPopUp-img-effect">
                                    <div className="projectPopUp-img-effect-desc">
                                        <div className="project-cat">{project.category}</div>
                                        <div className="project-desc">{project.location} | Project Value: {project.costDisplay}</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Col>
                )}
            </Row>

            <Row>
                <Col>
                    <div>
                        <Modal isOpen={open} toggle={toggle} className="modal-project" centered size="lg">

                            <ModalBody>
                                <Container>
                                    <Row>
                                        <Col lg="6" className="modal-col modal-project-img align-self-center">
                                            <img
                                                className="img-fluid"
                                                src={selectedProject.imagePath} />
                                        </Col>

                                        <Col lg="6" className="modal-col">
                                            <div className="modal-project-cat">
                                                {selectedProject.category}.
                                                <span className="modal-close-button float-right" onClick={closePopUp}><FontAwesomeIcon icon={faWindowClose}> </FontAwesomeIcon></span>
                                            </div>
                                            <div className="modal-project-info">
                                                {selectedProject.location} <span className="modal-project-info-sep">|</span> Project Value: {selectedProject.costDisplay}
                                            </div>
                                            <div className="modal-project-desc"
                                                dangerouslySetInnerHTML={{
                                                    __html: selectedProject.details
                                                }}>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </ModalBody>
                        </Modal>
                    </div>
                </Col>
            </Row>
        </Block>
    );
}
export default Projects;
