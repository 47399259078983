import React, { useEffect, useState } from 'react';

import './Components/Resources/Css/base.css';
import './Components/Resources/Css/custom.css';
import './Components/Resources/Css/gallery.css';
import './Components/Resources/Css/navMenu.css';
import 'bootstrap/dist/css/bootstrap.css';

import ApplicationRouting from './ApplicationRouting';

import Common from './Components/Shared/Common';
import RefAPI from './Components/Shared/RefAPI';
import CommonAPI from './Components/Shared/CommonAPI';

const Application = (props) => {

    const [brandCode, setBrandCode] = useState("");

    useEffect(() => {
        Common.traceInit("Application");

        var hostBrandCode = null;

        var search = document.location.search;
        var searchBrandCode = search.replace("?brandCode=", '');
        if (search.length > searchBrandCode.length) {
            hostBrandCode = searchBrandCode;
            setBrandCode(searchBrandCode);
        }
        var expireCache = search.replace("?expireCache=", '');
        if (search.length > expireCache.length) {
            CommonAPI.expireCache({}, function (data, callbackData) {
                Common.log("The cache has been expired");
                Common.log(data);
            });
        }

        if (hostBrandCode == null) {

            var host = document.location.host;
            var shortHost = host.replace("www.", "");
            Common.trace("setBrandCode for -> {0}", [host]);
            Common.trace("setBrandCode for short Host -> {0}", [shortHost]);

            //Prod URLS Map to avoid Server Call
            switch (shortHost.toLowerCase()) {

                case "r-tech.com.au": setBrandCode("RTech"); break;
                case "paellaking.com.au": setBrandCode("PK"); break;
                case "spearfishingacademy.com.au": setBrandCode("ASA"); break;

                case "birdsmouthpm.com.au": setBrandCode("BM"); break;
                case "birdsmouthpm.com": setBrandCode("BM"); break;
                case "birdsmouthpm.online": setBrandCode("BM"); break;
                case "birdsmouthpm.au": setBrandCode("BM"); break;

                case "wildfoodmeister.com.au": setBrandCode("WFM"); break;
                case "wildfoodmeister.au": setBrandCode("WFM"); break;

                case "adrenosydneykingfishcup.com.au": setBrandCode("KFC"); break;
                default:
            }
        }

        if (hostBrandCode == null) {

            RefAPI.getBrandCode(function setBrandCodeCallback(data, callbackData) {
                Common.log("getBrandCode Response");
                Common.log(data);

                if ((callbackData == null) || ((callbackData != null) && (callbackData.oldBrandCode !== data.brandCode))) {
                    Common.log("Override Brand Code -> {0} ", [data.brandCode]);
                    setBrandCode(data.brandCode);
                }
            });
        }

    }, [brandCode]);

    return (
        <ApplicationRouting brandCode={brandCode} />
    );
};
export default Application;
