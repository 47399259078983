import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Row, Col } from 'reactstrap';
import { ContentAbout0, ContentAbout1, ContentAbout2, ContentAbout3, ContentAbout4, ContentRegister0, ContentRegister1, ContentRegister2, ContentRegister3 } from './_ContentBlocks';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';

const Register = (props) =>{

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="register" isAlt={false}>

            <TextHeader text={
                <Fragment>
                    registering for the tournament
                </Fragment>
            } />

            <Row>
                <Col lg={{ site: 10, offset: 1 }} className="definition">
                    <ContentRegister0 />
                </Col>
            </Row>

            <ContentRegister1 />
            <ContentRegister2 />
        </Block>
    );
}
export default Register;
