import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Row, Col } from 'reactstrap';
import { ContentAbout0, ContentAbout1, ContentAbout2, ContentAbout3, ContentAbout4, ContentRules0, ContentRules1, ContentRules2, ContentRules3, ContentRules4 } from './_ContentBlocks';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';

const Rules = (props) =>{

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="rules" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    tournament rules and info
                </Fragment>
            } />

            <Row>
                <Col lg={{ size: 10, offset: 1 }} className="definition">
                    <ContentRules0 />
                </Col>
            </Row>

            <ContentRules1 />
            <ContentRules3 />
            {/*<ContentRules2 />*/}
            <ContentRules4 />
        </Block>
    );
}
export default Rules;
