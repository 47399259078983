import React, { Fragment, useEffect, useState } from 'react';

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

import { Row, Col } from 'reactstrap';

const StatementP2 = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="statementp1" isAlt={false}>

            <Row className="top-Inner no-gutters">
                <Col lg={{ size: 4, offset: 1 }}>

                    <TextHeader text={
                        <Fragment>
                            Attitudes
                        </Fragment>
                    } />

                    <p>
                        ASA have a relaxed way of passing our knowledge to you. We listen to what you wish to achieve and work on delivering that for you.
                    </p>
                    <p>
                        Depth and times mean little, this is spearfishing we do, it is meant to be enjoyable, we encourage a good time before anything.
                    </p>
                </Col>

                <Col lg={{ size: 4, offset: 1 }}>

                    <TextHeader text={
                        <Fragment>
                            Teaching
                        </Fragment>
                    } />

                    <p>
                        Our workshops are both theoretical and practical where we work on your mental approach, streamlining, relaxation in the water, and of course, hunting in its many forms is revealed.
                    </p>
                </Col>
            </Row>

        </Block>
    );
};

export default StatementP2;
