import React, { Fragment, useEffect, useState } from 'react';

import { Row, Col, Container, FormGroup, Input, Button } from 'reactstrap';

import './../Resources/css/block_contactUs.css'
import Common from '../../../Shared/Common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faEnvelopeOpen, faMobile } from '@fortawesome/free-solid-svg-icons';

import CommonAPI from '../../../Shared/CommonAPI';
import Block from '../../../Controls/Scafolding/Block';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

const ContactUs = (props) => {

    const [model, setModel] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    useEffect(() => {
    });

    const handleChange = e => {
        setModel({ ...model, [e.target.name]: e.target.value });
        Common.log("Changed Field {0} to {1}", [e.target.name, e.target.value]);
    }

    const sendMessage = () => {
        Common.trace("send message");
        Common.log(model);

        CommonAPI.sendMessage(
            model,
            null,
            {
                successMessage: "Thanks for your message; we will be in touch soon!!!",
                errorMessage: "An error Ocurred; please use the provided email or phone number to reach us"
            });
    }

    return (
        <Block name="contactUs">

            <TextHeader text={
                <Fragment>
                    <strong>C</strong>ontact <strong>P</strong>aella <strong>K</strong>ing <FontAwesomeIcon icon={faCrown} />
                </Fragment>
            } />

            <TextTitle text={
                <Fragment>
                    <p>Use the form below for any enquiries about avaliability or catering options (Include at least one contact method)</p>
                    <p>Or simply you can reach us at</p>
                    <ul className="no-bullets">
                        <li><FontAwesomeIcon icon={faEnvelopeOpen} /> <b><a href="mailto:info.paellaking@gmail.com">info.paellaking@gmail.com</a></b> </li>
                        <li><FontAwesomeIcon icon={faMobile} /> <b>044 967 99 59</b></li>
                    </ul>
                </Fragment>
            } />

            <Row>
                <Col lg="6">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <Input type="text" placeholder="Name / First Name" name="name" value={model.name} onChange={handleChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <Input type="text" placeholder="Email" name="email" value={model.email} onChange={handleChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <Input type="text" placeholder="Phone Number" name="phone" value={model.phone} onChange={handleChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <Input type="text" placeholder="Subject" name="subject" value={model.subject} onChange={handleChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col lg="6">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <textarea className="form-control" name="message" placeholder="Message" value={model.message} onChange={handleChange} rows="8" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <Button className="form-control" color="secondary" onClick={sendMessage}>
                                        <FontAwesomeIcon icon={faEnvelopeOpen} /> Send Message
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                </Col>

            </Row>

        </Block>
    );
};

export default ContactUs;
