import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './../Resources/css/Partner.css';

import Block from '../../../Controls/Scafolding/Block';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { facebook, instagram, twitter } from '@fortawesome/free-solid-svg-icons'

import logo_adreno from '../Resources/Images/Logos/logo-adreno.png'
import logo_salvimar from '../Resources/Images/Logos/logo-salvimar.png'
import logo_usfa_big from '../Resources/Images/Logos/logo-usfa-big.png'
import { faAlignCenter, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import Common from '../../../Shared/Common';

const Partners = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="partners" isAlt={true}>

            <Row>
                <Col lg="6">

                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <h3>Our Partners</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <a href="http://www.spearfishing.com.au" target="_blank"><img className="img-thumbnail" src={logo_adreno} alt="Adreno Spearfishing" width="150px"/></a>
                            </Col>
                            <Col lg="4">
                                <a href="http://www.usfa.org.au" target="_blank"><img className="img-thumbnail" src={logo_usfa_big} alt="Underwater Skindivers and Fisherman Association" width="150px"/></a>
                            </Col>
                            <Col lg="4">
                                <a href="https://www.salvimar.com/" target="_blank"><img className="img-thumbnail" src={logo_salvimar} alt="Salvimar Spearfishing" width="150px" /></a>
                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col lg="6">
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <h3>Find us on Social Media</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <a href="https://facebook.com/"><FontAwesomeIcon  /></a>
                            </Col>
                            <Col lg="4">
                                <a href="https://instagram.com/"><FontAwesomeIcon /></a>
                            </Col>
                            <Col lg="4">
                                <a href="https://twitter.com/"><FontAwesomeIcon /></a>
                            </Col>
                        </Row>
                    </Container>
                </Col>

            </Row>

            <Row>
                <Col lg="12" className="copyright text-center">
                    Copyright &copy; 2022 <span>Australia Spearfishing Academy</span>
                </Col>
            </Row>

        </Block>
    );
};

export default Partners;

