import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThermometerFull, faCheck, faFish, faDollarSign } from '@fortawesome/free-solid-svg-icons';

export const ContentEvents = props => 
    <Fragment>
        <h4>How?</h4>
        <p>
            We cook the paella on site; balcony, backyard, park, by the beach... wherever your party is.
                    It makes a better experience when everyone sees the food coming along while enjoying some <i className="fa fa-glass"></i> drinks
                </p>
        <h4>For How Many?</h4>
        <p>
            We cater for all <i className="fa fa-user-times"></i> groups and sizes; from <b>20 to 120 servings.</b>
        </p>
        <p>
            We also cook if required multiple paellas (of different sort if required); a favourite <b>for coporate events is the combination of a 40 / 50 people with the Lot and a 20 / 30 vegetarian</b>
        </p>
        <h4>Where</h4>
        <p>
            Anywhere in Sydney and around. If from outside of Sydney just contact us, we love to make an excuse for a weekend away!!!
                </p>
        <h4>Events..</h4>
        <p>
            Any excuse is good for Paella...
        </p>
        <div>
            <ul>
                <li><i className="fa fa-check"></i> Birthday</li>
                <li><i className="fa fa-check"></i> Aniversaries</li>
                <li><i className="fa fa-check"></i> Crisennings</li>
                <li><i className="fa fa-check"></i> Engagements</li>
                <li><i className="fa fa-check"></i> ... and corporate events</li>
            </ul>
        </div>
        <p>
            We always arrive on time to your site and deliver the food when requested just like a <i className="fa fa-clock-o"></i> swiss clock!!!!
        </p>
    </Fragment>;

export const ContentPaellaTheLot = props =>
    <Fragment>
        <h3><strong>T</strong>he <strong>L</strong>ot</h3>
        <p>
            By far our most pouplar. We love it a bit <FontAwesomeIcon icon={faThermometerFull} /> <strong>S</strong>picy; contains:
        </p>
        <div>
            <ul>
                <li><FontAwesomeIcon icon={faCheck} /> Prawns</li>
                <li><FontAwesomeIcon icon={faCheck} /> Mussels</li>
                <li><FontAwesomeIcon icon={faCheck} /> Camalari & Octopus</li>
                <li><FontAwesomeIcon icon={faCheck} /> Chorizo</li>
                <li><FontAwesomeIcon icon={faCheck} /> Chiken</li>
                <li><FontAwesomeIcon icon={faCheck} /> Vegetables</li>
            </ul>
        </div>
        <p>
            The Stock is made from mussles and fresh fish (snapper, breams and/or flatheads).
        </p>
    </Fragment>;

export const ContentPaellaSeafoodOnly = props =>
    <Fragment>
        <h3><strong>Sea</strong>food Only</h3>
        <p>
            For the ocean lovers <FontAwesomeIcon icon={faFish} />; contains:
        </p>
        <div>
            <ul>
                <li><FontAwesomeIcon icon={faCheck} /> Prawns</li>
                <li><FontAwesomeIcon icon={faCheck} /> Mussels</li>
                <li><FontAwesomeIcon icon={faCheck} /> Camalari & Octopus</li>
                <li><FontAwesomeIcon icon={faCheck} /> Bay Squid</li>
                <li><FontAwesomeIcon icon={faCheck} /> Pipis</li>
                <li><FontAwesomeIcon icon={faCheck} /> Vegetables</li>
            </ul>
        </div>
        <p>
            The Stock is made from mussles, Pipis (optional) and fresh fish (snapper, breams and/or flatheads).
        </p>
    </Fragment>;

export const ContentPaellaVeg = props =>
    <Fragment>
        <h3><strong>V</strong>egeterian</h3>
        <p>
            For those who don't eat meat or looking for something diffeent.
            A must in corporate events (next to one with the lot!!!)
        </p>
        <div>
            <ul>
                <li><i className="fa fa-check"></i> Green Beans</li>
                <li><i className="fa fa-check"></i> Carrots</li>
                <li><i className="fa fa-check"></i> Capsicum</li>
                <li><i className="fa fa-check"></i> Mushrooms</li>
                <li><i className="fa fa-check"></i> Tofu</li>
                <li><i className="fa fa-check"></i> Cheese</li>
                <li><i className="fa fa-check"></i> Tomtoes</li>
                <li><i className="fa fa-check"></i> Corn</li>
            </ul>
        </div>
        <p>
            We use a Vegetable stock with Onions, Tomatoes, Capsicums, Celery and herbs..
        </p>
    </Fragment>;

export const ContentPriceTheLot = props =>
    <Fragment>
        <div className="rate"><span>from </span>$15.00 </div>
        <h2 className="price-header">The Lot</h2>
        <p>Our Most popular</p>
        <ul>
            <li>20 servings - <FontAwesomeIcon icon={faDollarSign} /> 750</li>
            <li>30 servings - <FontAwesomeIcon icon={faDollarSign} /> 850</li>
            <li>40 servings - <FontAwesomeIcon icon={faDollarSign} /> 950</li>
            <li>50 servings - <FontAwesomeIcon icon={faDollarSign} /> 1050</li>
            <li>60 servings - <FontAwesomeIcon icon={faDollarSign} /> 1150</li>
            <li>70 servings - <FontAwesomeIcon icon={faDollarSign} /> 1250</li>
            <li>80 servings  - <FontAwesomeIcon icon={faDollarSign} /> 1350</li>
            <li>up to 100 servings  - <FontAwesomeIcon icon={faDollarSign} /> 1500</li>
        </ul>
    </Fragment>;

export const ContentPriceVeg = props =>
    <Fragment>
        <div className="rate"><span >from </span>$15.00 </div>
        <h2 className="price-header">Vegetarian</h2>
        <p>Alone or as complement for lot in your events...</p>

        <h2>Seafood only</h2>
        <p>For the true Ocean lovers..</p>

        <h2>Halal or Kosher</h2>
        <p>With selected butchers on each case</p>

    </Fragment>;



export const ContentPaellaSpecial = props =>
    <Fragment>
        <h3>Something <strong>different</strong></h3>
        <p>
           Or dare us to do something different <strong>S</strong>picy; contains:
        </p>
        <p>
            Want to add some lobsters? Pipis? Scampi, Balmain Bugs...
        </p>
        <p>
           Just ask !!!!
        </p>
    </Fragment>;