import React, {  useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconWrapper = (props) => {
    const [icon, setIcon] = useState("");

    useEffect(() => {
        setIcon(props.icon);
    });

    return (
        <div className="featured-item">
            <div className="icon">
                <div className="icon-style">
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
        </div>
    );
}

export default IconWrapper;