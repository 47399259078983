import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import { Container, Row, Col } from 'reactstrap';

import './../Resources/css/block_top.css'
import Block from '../../../Controls/Scafolding/Block';

const Top = (props) => {

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="top" isAlt={true}>

            <Row className="top-inner-lg d-none d-sm-block">
                <Col lg="12">
                </Col>
            </Row>

            <Row className="top-inner-xs d-block d-sm-none">
                <Col lg="12">
                </Col>
            </Row>

            <Row className="">
                <Col lg="12">
                    <Container>
                        <Row>
                            <Col lg="6" className="d-none d-sm-block top-message d-flex justify-content">
                                providing boutique and personalised project management services tailored to your project specific needs across aged care, residential and education sectors
                            </Col>

                            <Col lg="6" className="d-none d-sm-block top-war-cry top-war-cry-lg align-self-center">
                                strength <span className="top-war-cry-sep">|</span> integrity <span className="top-war-cry-sep">|</span> support
                            </Col>

                            <Col lg="7" className="d-block d-sm-none top-message d-flex justify-content">
                                providing boutique and personalised project management services tailored to your project specific needs across aged care, residential and education sectors
                            </Col>

                            <Col lg="5" className="d-block d-sm-none top-war-cry top-war-cry-xs align-self-center">
                                strength <span className="top-war-cry-sep">|</span> integrity <span className="top-war-cry-sep">|</span> support
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="top-inner-bottom">
            </Row>
        </Block>
    );
};
export default Top;
