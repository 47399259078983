import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Row, Col } from 'reactstrap';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';

const Dates = (props) =>{

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="dates" isAlt={false}>

            <TextHeader text={
                <Fragment>
                    Dates to remember
                </Fragment>
            } />

            <ContentAboutWrapper
                header="The information night – Thursday 31st October Adreno Sydney"
                content={
                    <Fragment>
                        <p>
                            To kick off the biggest event on Sydney's spearfishing seasonal calendar we host a social & information night to bring the community together under one roof to set the scene for the following weekends event.
                        </p>
                        <p>
                            This Kingfish cup was born out of necessity, a competition built to bring our communities together in a sustainable and ethically focused competition with divers from all around Australia participating. A competition driven by inclusion, from the most experienced to aspiring greenhorn learning the ropes and piecing it together. This is what spearfishing is all about. Mateship, adventure, respect for the fishery and healthy competition.
                        </p>
                        <p>
                            The Kingfish Cup has been an outstanding success in achieving all of this.
                        </p>
                        <p>
                            A Q&A panel with kingfish gurus such as last year’s Champion Keiran Timp, former champion Bob McComb, Derrick Cruz and other legends.  The night's goal is to share experiences and knowledge around Sydney Spearfishing with a focus on the Yellowtail Kingfish species. NSW Fisheries  Kingfish experts will be in attendance and present some great Kingfish findings on the night, and again Fisheries scientists will be in attendance at the weigh ins to record the captures for their invaluable research.  An evening meal catered by the Paella King Rico, throw in  some sensational door prizes for those in attendance on the night, and to those who early bird register for the Cup, the night is shaping up to be a ripper.
                        </p>
                        <p>
                            This social event will be bigger than the Cup itself!
                        </p>
                    </Fragment>
                } />


            <ContentAboutWrapper
                header="Adreno Sydney Kingfish Cup  9 - 10th November"
                content={
                    <Fragment>
                    </Fragment>
                } />


            <ContentAboutWrapper
                header="The Presentation Thursday 14th November from 17:30 pm"
                content={
                    <Fragment>
                        Another fantastic social evening, where many of the entrants gather, again at Adreno Sydney Superstore, mingle and talk about the one that got away while enjoying some quality food while anticipating the results.  Sponsors for the event are amazing and the 2023 event saw over $15 000 of prizes given out we are on track for a similar prize pool.  
                    </Fragment>
                } />

        </Block>
    );
}
export default Dates;

const ContentAboutWrapper = (props) => {
    return (
        <Row>
            <Col lg={{ site: 10, offset: 1 }} className="definition">
                <p>
                    <b>{props.header}</b>
                </p>
                <p>
                    {props.content}
                </p>
            </Col>
        </Row>
    );
}