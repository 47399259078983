import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import Application from './Application';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Application/>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();
unregister();

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
        registration.unregister();

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async (names) => {
                await Promise.all(names.map(name => caches.delete(name)));
            });
        }
    });
}
