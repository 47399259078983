import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../Shared/Common';

const ImageInsert = (props) => {

    const [blockName, setBlockName] = useState("");
    const [id] = useState("id-" + props.addClass);
    const [addClass] = useState("imageInsert " + props.addClass);
    const [addClassInner] = useState("imageInsertInner");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Fragment>

            <div className="d-none d-md-block">

                <div className={addClass}>
                    <div className={addClassInner}>
                    </div>
                </div>

            </div>

            <div id={id} className="d-md-none">
            </div>

        </Fragment>
    );
}
export default ImageInsert;

