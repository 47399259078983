import React, { Fragment, useEffect, useState, Component } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Row, Col, FormGroup, Button } from 'reactstrap';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';
import DataAPI from '../Shared/DataAPI';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import '../Resources/css/site.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const KingFishCupAllFish = (props) => {

    const [blockName, setBlockName] = useState("");
    const [val, setVal] = useState();
    const [filterText, setFilterText] = useState("");
    const [fish, setFish] = useState([]);

    const gridColumns = [
        { field: "diverName", hide: false, headerName: 'Who' },
        { field: "name", hide: true },
        {
            field: "changeLog.createdDate", filter: true, width: 250, headerName: 'When',
            cellRenderer: TextCellRenderer,
            cellRendererParams: [{
                renderFunction: function (value) { return new Date(value).toDateString(); }
            }]
        },
        { field: "location", hide: false },
        {
            field: "length", hide: false,
            cellRenderer: TextCellRenderer,
            cellRendererParams: [{
                renderFunction: function (value) { return format("{0} cm", [value]); }
            }]
        },
        { field: "weight", hide: false,
            cellRenderer: TextCellRenderer,
            cellRendererParams: [{                
                renderFunction: function (value) { return formatWeight(value); }
            }]
        },
    ];

    let getAllFishCallback = (data) => {
        Common.log(data);
        setFish(data);
    };

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);

        DataAPI.getAllFish({}, getAllFishCallback);
    }, [setFish]);   

    let onSearchInputChange = function (item, event) {
        setFilterText(item.target.value);
    }

    function format(inputString, params) {
        if ((params != null) && (params.length > 0)) {
            var i = params.length;
            while (i--) {
                inputString = inputString.replace(new RegExp('\\{' + i + '\\}', 'gm'), params[i]);
            }
        }
        return inputString;
    };

    function formatWeight(weight) {

        if (weight.length == 4) {
            var kg = weight.substring(0, 1);
            var grms = weight.substring(1, 3);

            return format("{0},{1} kg", [kg, grms]);
        }
        if (weight.length == 5) {
            var kg = weight.substring(0, 2);
            var grms = weight.substring(2, 3);

            return format("{0},{1} kg", [kg, grms]);
        }
        return format("{0} kg", [weight]);
    };

    return (
        <Block name="operations" isAlt={false}>

            <TextHeader text={
                <Fragment>
                    Caught Kingfish for the Event
                </Fragment>
            } />

            <Row>
                <Col>
                    <div id="gridDivers" className="ag-theme-alpine" style={{ height: 750 }}>
                        <AgGridReact
                            columnDefs={gridColumns}
                            rowData={fish}
                        />
                    </div>
                </Col>
            </Row>

        </Block>
    );
}
export default KingFishCupAllFish;


class TextCellRenderer {
    // init method gets the details of the cell to be renderer
    init(params) {

        function format(inputString, params) {
            if ((params != null) && (params.length > 0)) {
                var i = params.length;
                while (i--) {
                    inputString = inputString.replace(new RegExp('\\{' + i + '\\}', 'gm'), params[i]);
                }
            }
            return inputString;
        };

        function formatWeight(weight) {

            if (weight.length == 4) {
                var kg = weight.substring(0, 1);
                var grms = weight.substring(1, 3);

                return format("{0},{1} kg", [kg, grms]);
            }
            if (weight.length == 5) {
                var kg = weight.substring(0, 2);
                var grms = weight.substring(2, 3);

                return format("{0},{1} kg", [kg, grms]);
            }
            return format("{0} kg", [weight]);
        };

        this.eGui = document.createElement('span');
        this.eGui.innerHTML = params[0].renderFunction(params.value);
    }

    getGui() {
        return this.eGui;
    }

    refresh(params) {
        return false;
    }
}

