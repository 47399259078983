import React, { Fragment, useEffect, useRef, useState } from 'react';
import './../Resources/css/block_whyUs.css'

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Row, Col, Container } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity, faBirthdayCake, faShoppingBasket, faCheck, faCar, faThermometer, faBolt, faUtensilSpoon } from '@fortawesome/free-solid-svg-icons'

import imgLogo from './../Resources/Images/PKLogo.png'
import imgMe1 from './../Resources/Images/Me_1.jpeg'
import imgMe2 from './../Resources/Images/Me_2.jpeg'
import imgMe3 from './../Resources/Images/Me_3.jpeg'
import imgMe4 from './../Resources/Images/Me_4.jpeg'
import imgMe5 from './../Resources/Images/20210411_075100526_iOS.jpg'

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';


const WhyUs = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="whyUs">

            <TextHeader text={
                <Fragment>
                    <img src={imgLogo} className="img-fluid" width="400px" />
                </Fragment>
            } />

            <TextHeader text={
                <Fragment>
                    <FontAwesomeIcon icon={faUtensilSpoon} /><strong>W</strong>hy <strong>U</strong>s
                </Fragment>
            } />

            <TextTitle text={
                <Fragment>
                    Why you should consider <b>The Paella King</b> for your next event...
                </Fragment>
            } />

            <Container>
                <Row>
                    <Col lg={6}>
                        <IconWrapper icon={faUniversity} />
                        <div className="meta-text">
                            <h3>Tradition</h3>
                            <p>We cook our Paellas in the traditional Spanish fashion</p>
                            <p>We cook them slowly using a Valencia's recipe for mixed meat and seafood paellas</p>
                            <p>We have years of experience and we never fail to get a perfect flavour</p>
                        </div>
                    </Col>

                    <Col lg={6} className="text-center">
                        <img src={imgMe1} className="img-fluid img-border img-square" />
                    </Col>

                </Row>
                <Row>
                    <Col lg={6} className="text-center d-none d-lg-block">
                        <img src={imgMe3} className="img-fluid img-border img-square" />
                    </Col>

                    <Col lg={6}>
                        <IconWrapper icon={faBirthdayCake} />
                        <div className="meta-text">
                            <h3>All Ocassions</h3>
                            <p>
                                We cater for all ocassions and requirements; we have done plenty of birthday and engagement parties, social and / or work events and we accomdate to your taste and dietary requirements;
                                We cook different types of paellas
                            </p>
                            <ul>
                                <li><FontAwesomeIcon icon={faCheck} /> <b>The Works</b> <i>our most popular</i> Plenty of Meat and Seafood (better when with a bit of an <FontAwesomeIcon icon={faThermometer} /> spicy kick)</li>
                                <li><FontAwesomeIcon icon={faCheck} /> <b>Vegetarian</b></li>
                                <li><FontAwesomeIcon icon={faCheck} /> <b>Fully Vegan</b></li>
                                <li><FontAwesomeIcon icon={faCheck} /> <b>Halal</b> (We know couple of Halal butchers who prepare amazing beef cured chorizo!!!)</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <IconWrapper icon={faBolt} />
                        <div className="meta-text">
                            <h3>Passion</h3>
                            <p>We like bringing people and food together</p>
                            <p>And pride in cooking our Paellas to perfection</p>
                            <p>That is why we never rush (It does take some 1:45 <i className="fa fa-clock-o"></i> to cook our Paellas end to end)</p>
                        </div>
                    </Col>
                    <Col lg={6} className="text-center">
                        <img src={imgMe5} className="img-fluid img-border img-square" />
                    </Col>
                </Row>
                <Row>

                    <Col lg="6" className="text-center">
                        <img src={imgMe2} className="img-fluid img-border img-square" />
                    </Col>

                    <Col lg="6">

                        <IconWrapper icon={faShoppingBasket} />
                        <div className="meta-text">
                            <h3>Quality</h3>
                            <p>We put the best ingridents;
                            </p>

                            <ul>
                                <li><FontAwesomeIcon icon={faCheck} /> Our Saffron is imported from Dubai.</li>
                                <li><FontAwesomeIcon icon={faCheck} /> The stock use to flavour our meat paellas is made of local fish (snapper, bream and flatthead) and mussles.</li>
                                <li><FontAwesomeIcon icon={faCheck} /> For the Vegetarian paellas a delicious sweet Capsicum, Onion and tomato based stock is used.</li>
                                <li><FontAwesomeIcon icon={faCheck} /> Prawns, Calamari, Octopus and Mussels are source from the Sydney Fish Markets.</li>
                                <li><FontAwesomeIcon icon={faCheck} /> Our Butchers (halal and non halal) only provides the best meats (we tend to preffer spicy meats...)</li>
                            </ul>
                        </div>
                    </Col>

                    <Col lg="6">
                        <IconWrapper icon={faCar} />
                        <div className="meta-text">
                            <h3>Accomodating and Easy</h3>
                            <p>We make the experience easy for you.</p>
                            <p>We will come to you to cook the Paella on Site.</p>
                            <p>Everything is prepared before hand.</p>
                            <p>Although it takes a some 1:45 min - 2 hours to cook; the process makes your party and event even a better experience to your guests (there are always plenty of guests taking pictures!!)</p>
                            <p>We adapt to your needs, dietary requirements and alergies (All our paellas are gluten free!!!)</p>
                        </div>
                    </Col>

                    <Col lg={{ size: 6 }} className="text-center d-none d-lg-block">
                        <img src={imgMe4} className="img-fluid img-border img-square" />
                    </Col>

                    <Col lg={{ size: 6 }} className="text-center d-lg-none">
                        <img src={imgMe4} className="img-fluid img-border img-square" />
                    </Col>
                </Row>
            </Container>
        </Block>
    );
};

export default WhyUs;
