import React, { useEffect, useState } from "react";
import Common from "../../Shared/Common";

const AppWrapper = (props) => {
    const [wrapClass, setWrapClass] = useState("");

    useEffect(() => {
        Common.traceInit("App Wrapper");
        setWrapClass(props.wrapClass);
    }, [props.wrapClass]);

    return (
         <div className={wrapClass}>
            {props.children}
        </div>
    );
};

export default AppWrapper;
