import React, { Fragment, useEffect, useState } from 'react';
import './../Resources/css/Feedback.css'

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

import { Container, Row, Col, Button } from 'reactstrap';

import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import CommonAPI from '../../../Shared/CommonAPI';

const Feedback = (props) => {
    const [blockName, setBlockName] = useState("");
    const [testimonialGroups, setTestimonialGroups] = useState([]);
    const [index, setIndex] = useState(0);

    let getActiveTestimonialsCallback = (data) => {
        setTestimonialGroups(data.groups);
    };

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);

        CommonAPI.getActiveTestimonialsTransformed({}, getActiveTestimonialsCallback);

    }, [setTestimonialGroups]);

    const slideTo = (newIndex) => {
        setIndex(newIndex);
    }
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const handleNext = (event) => { };
    const handlePrev = (event) => { };

    return (
        <Block name="Testimonials">

            <TextHeader text={
                <Fragment>
                    ...What our students say about us
                </Fragment>
            } />

            <Row>
                <Col className="text-center">
                    <Carousel fade activeIndex={index} onSelect={handleSelect} slide={true} interval={null} next={handleNext} previous={handlePrev}>
                        {testimonialGroups.map((group, groupIndex) =>
                            <CarouselItem key={groupIndex} index={groupIndex}>
                                <div className="container-fluid">
                                    <div className="row">
                                        {group.testimonials.map((testimonial, index) =>
                                            <div key={index} className="col-md-3">
                                                <div className="testimonial">
                                                    <h4 className="text-success font-weight-bold mt-4">{testimonial.who}</h4>
                                                    <p className="font-weight-normal">
                                                        <i className="fa fa-quote-left pr-2"></i>
                                                        <span>{testimonial.content}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </CarouselItem>
                        )}
                    </Carousel>
                </Col>
            </Row>


            <Row>
                <Col className="text-center">
                    <div className="carousel-indicators">
                        {testimonialGroups.map((group, groupIndex) =>
                            <Fragment key={groupIndex}>
                                <Button className="carousel-slider-item bg-success" onClick={() => slideTo(groupIndex)}><span> </span></Button>
                            </Fragment>
                        )}
                    </div>
                </Col>
            </Row>

        </Block>
    );
};
export default Feedback;

