import React, { Fragment, useEffect, useState } from 'react';
import Block from '../../../Controls/Scafolding/Block';
import Common from '../../../Shared/Common';

import './../Resources/css/block_champions.css'

import { Row, Col } from 'reactstrap';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

const ChampionIntro = (props) => {

    return (
        <Col lg="12">
            <TextTitle text={
                <Fragment>
                    <p className="">
                        {props.text}
                    </p>
                </Fragment>
            } />
        </Col >
    );
}

const Champion = (props) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    }

    const openPopUp = (project) => {
        Common.log("open Project pop up");
        Common.log(project);

        setOpen(!open);
    }

    const closePopUp = () => {
        setOpen(false);
    }

    return (
        <Col lg="4" className="">

            <div className="projectPopUp">
                <a onClick={openPopUp.bind(this, props)}>
                    <div className="projectPopUp-img">
                        <img className="img-fluid"                      
                            src={props.imagePath}
                            />
                    </div>

                    <div className="projectPopUp-img-effect">
                        <div className="projectPopUp-img-effect-desc">
                            <div className="project-cat">{props.name}</div>
                            <div className="project-desc">{props.category} | Weight: {props.weight} kg</div>
                        </div>
                    </div>
                </a>
            </div>
        </Col>
    );
}

const Champions = (props) => {

    const [blockName, setBlockName] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState([]);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    }, [setProjects]);

  

    return (
        <Block name="projects" isAlt={false}>

            <TextHeader text={
                <Fragment>
                    the Champions
                </Fragment>
            } />

            <Row>
                <ChampionIntro
                    text="2019 - The inagural's Cup saw local Bondi diver Grayson and Vita (Sanc Souci Dolphins President) taking the GOLD home"
                />

                <Champion
                    imagePath="/Brands/KFC/Champions/2019_Seniors.jpg"
                    category="Seniors"
                    name="Grayson"
                    weight="20" />

                <Champion
                    imagePath="/Brands/KFC/Champions/2019_Women.jpg"
                    category="Female"
                    name="Vita"
                    weight="13.4" />

                <Champion
                    imagePath="/Brands/KFC/Champions/2019_Juniors.jpg"
                    category="Juniors"
                    name="Grayson"
                    weight="20" />
            </Row>

            <Row>
                <ChampionIntro
                    text="2020 - The experience of Bob 'The Spearfishing Bible' paid off with Grayson repeating on the winners seat"
                />

                <Champion
                    imagePath="/Brands/KFC/Champions/2020_Seniors.jpg"
                    category="Seniors Male"
                    name="Bob"
                    weight="15" />

                <Champion
                    imagePath="/Brands/KFC/Champions/2020_Juniors.jpg"
                    category="Juniors"
                    name="Grayson"
                    weight="18" />
            </Row>

            
            <Row>
                <ChampionIntro
                    text="2021 - Tough conditions with poor viz and big seas; Marty and Beau really braved the elements to win the 3rd KFC!!!"
                />

                <Champion
                    imagePath="/Brands/KFC/Champions/2021_Seniors.jpg"
                    category="Seniors Male"
                    name="Marty"
                    weight="15.1" />

                <Champion
                    imagePath="/Brands/KFC/Champions/2021_Juniors.jpg"
                    category="Juniors"
                    name="Beau"
                    weight="12" />
            </Row>


            <Row>
                <ChampionIntro
                    text="2022 - Cold front of water turned the viz off making it a hard tournament!!!"
                />

                <Champion
                    imagePath="/Brands/KFC/Champions/2022_Seniors.jpg"
                    category="Seniors Male"
                    name="Michael Ward"
                    weight="14.9" />

                <Champion
                    imagePath="/Brands/KFC/Champions/2022_Seniors2.jpg"
                    category="Seniors Male 2nd Place"
                    name="Jai Gibbons"
                    weight="13.8" />

                <Champion
                    imagePath="/Brands/KFC/Champions/2022_Seniors3.jpg"
                    category="Seniors Male 2nd Place"
                    name="Artie"
                    weight="10.4" />
            </Row>


            <Row>
                <ChampionIntro
                    text="2023 - Our biggest year so far!!!"
                />

                <Champion
                    imagePath="/Brands/KFC/Champions/2023_Seniors.jpg"
                    category="Seniors Male"
                    name="Keiran Timp"
                    weight="21.4" />

                <Champion
                    imagePath="/Brands/KFC/Champions/2023_Seniors2.jpg"
                    category="Seniors Male 2nd Place"
                    name="Steve Montgomery"
                    weight="20.1" />

                <Champion
                    imagePath="/Brands/KFC/Champions/2023_Seniors3.jpg"
                    category="Third place"
                    name="Brice Lamarque"
                    weight="19.7" />

            </Row>
        </Block>
    );
}
export default Champions;
