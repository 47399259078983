import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import './../Resources/css/block_meetTheDirector.css';

import { Row, Col } from 'reactstrap';
import { ContentWhoAmI1, ContentWhoAmI2, ContentWhoAmI3 } from './_ContentBlocks';
import imgProfile from '../Resources/Images/FacePhoto.png';
import Block from '../../../Controls/Scafolding/Block';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';

const MeetTheDirector = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (

        <Block name="meetTheDirector" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    meet the director
                </Fragment>
            } />

            <Row className="row-bkg">

                <Col lg="8">
                    <ContentWhoAmI1 />
                    <ContentWhoAmI2 />
                    <ContentWhoAmI3 />
                </Col>

                <Col lg="4" className="align-self-center meetTheDirector-image-col">
                    <img src={imgProfile} className="img-fluid meetTheDirector-image" />
                </Col>

            </Row>

        </Block>
    );
}
export default MeetTheDirector;