import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Container, Row, Col, FormGroup, Button, Label, Input, Alert } from 'reactstrap';
import { ContentAbout0, ContentAbout1, ContentAbout2, ContentAbout3, ContentAbout4 } from '../Blocks/_ContentBlocks';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faSave, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataAPI from '../Shared/DataAPI';
import TShirt from './../Resources/Images/TShirt.jpg';

export const KingFishCupSignUp = (props) => {

    const [blockName, setBlockName] = useState("");
    const [diverName, setDiverName] = useState(props.emailAddress);
    const [diver, setDiver] = useState({ id: 0 });
    const [diverFound, setDiverFound] = useState(false)
    const [actionSaved, setActionSaved] = useState(false);

    const [selectedOption, setSelectedOption] = useState("");
    const [headlandName, setHeadlandName] = useState("");
    const [headlandNotSelelected, setHeadlandNotSelelected] = useState(false);

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    let userSelectedCallback = (diver) => {
        setDiver(diver);
        setDiverFound(true);
    };

    let onHeadlandInputChange = function (item, event) {
        setHeadlandName(item.target.value);
    };

    let signOn = function () {
        setHeadlandNotSelelected(false);
        if (headlandName == '') {
            setHeadlandNotSelelected(true);
        } else {
            let fullHeadland = headlandName;
            DataAPI.signOn({ diverId: diver.id, divingLocation: fullHeadland }, actionCallBack);
        }
    };

    let actionCallBack = function (data, callbackData) {
        setActionSaved(true);
    }

    let actionCallbackError = function (data, callbackData) {
        setActionSaved(true);
    }

    return (
        <KingFishCupHeader
            headerMessage="Register for the Kingfish Cup"
            actionSuccessful={actionSaved}
        >
            <Container fluid>
                <Row>
                    <Col lg="9">
                        <FormGroup>
                            <p>
                                In order to participate in the <b>Adreno Sydney Kingfish cup</b> you need firstly to be a <b>USFA</b> member and purchase a ticket for the event.
                            </p>
                            <p>
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={TShirt} className="img-fluid img-border-light img-square" />
                                        </Col>
                                        <Col>
                                            <p>
                                                You can also get your 2024 Official Kingfish Up TShirt for $30 and other Cool Merchandise !!!!
                                            </p>
                                            <p>
                                                You can collect your TShirt at Adreno anytime before the cup (or in the presentation night)
                                            </p>
                                            <p>
                                                Visit the - <a href="https://usfa.tidyhq.com/shop/products"><b>USFA online Shop</b></a>
                                            </p>
                                        </Col>
                                    </Row>
                                </Container>
                            </p>
                            <p>
                                For <b>non USFA Member</b>; please select one of the two Options:
                            </p>
                            <ul>
                                <li><a href="https://usfa.tidyhq.com/public/membership_levels/737736821a90" target="_blank">Weekend <b>USFA</b> Membership & Cup Fee <b>$40</b> ($20 Membership + $20 Cup)</a></li>
                                <li><a href="https://usfa.tidyhq.com/public/membership_levels/29b3024329b5" target="_blank">Annual <b>USFA</b> Membership & Cup Fee <b>$130</b> ($110 Membership + $20 Cup)</a></li>
                            </ul>

                            {/*<ul>*/}
                            {/*    <li><a href="https://usfa.tidyhq.com/public/membership_levels/c134fec48903" target="_blank">Weekend <b>USFA</b> Membership & Cup Fee & T-Shirt <b>$65</b> ($20 Membership + $20 Cup + $30 T-Shirt)</a></li>*/}
                            {/*    <li><a href="https://usfa.tidyhq.com/public/membership_levels/737736821a90" target="_blank">Annual <b>USFA</b> Membership & Cup Fee & T-Shirt <b>$144</b> ($110 Membership + $20 Cup + $30 T-Shirt)</a></li>*/}
                            {/*</ul>*/}
                        </FormGroup>
                    </Col>
                </Row>
            </Container>

            <KingfishCupFindDiver
                emailAddress={diverName}
                message="Already a USFA member; enter your email address"
                userSelectedCallback={userSelectedCallback}
            />

            {diverFound && (
                <Container>
                    <Row>
                        <Col lg="9">
                            <FormGroup>

                                <ul>
                                    <li><a href="https://usfa.tidyhq.com/public/membership_levels/dfefe7331fe3" target="_blank">Click the link for the $20 Cup Fee</a></li>
                                   {/* <li><a href="https://usfa.tidyhq.com/public/membership_levels/dfefe7331fe3" target="_blank">Click the link for the $50 Cup Fee + T-Shirt</a></li>*/}
                                </ul>
                                
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            )}

        </KingFishCupHeader>
    );
}

export const KingFishCupSignOn = (props) => {

    const [blockName, setBlockName] = useState("");
    const [diverName, setDiverName] = useState(props.emailAddress);
    const [diver, setDiver] = useState({ id: 0 });
    const [diverFound, setDiverFound] = useState(false)
    const [actionSaved, setActionSaved] = useState(false);

    const [selectedOption, setSelectedOption] = useState("");
    const [headlandName, setHeadlandName] = useState("");
    const [headlandNotSelelected, setHeadlandNotSelelected] = useState(false);

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    let userSelectedCallback = (diver) => {
        setDiver(diver);
        setDiverFound(true);
    };

    let onHeadlandInputChange = function (item, event) {
        setHeadlandName(item.target.value);
    };

    let signOn = function () {
        setHeadlandNotSelelected(false);
        if (headlandName == '') {
            setHeadlandNotSelelected(true);
        } else {
            let fullHeadland = headlandName;
            DataAPI.signOn({ diverId: diver.id, divingLocation: fullHeadland }, actionCallBack);
        }
    };

    let actionCallBack = function (data, callbackData) {
        setActionSaved(true);
    }

    let actionCallbackError = function (data, callbackData) {
        setActionSaved(true);
    }

    return (
        <KingFishCupHeader
            headerMessage="Sign On"
            actionSuccessful={actionSaved}
        >

            <Row>
                <Col>
                    <p>
                        <Alert color="primary">
                            Before entering the water; Sign On here<br/>
                            Tell us where you are diving in case we need to locate you
                        </Alert>
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>
                        <Alert color="primary">
                            You can sign on / off multiple times during the day / weekend
                        </Alert>
                    </p>
                </Col>
            </Row>

            <KingfishCupFindDiver
                emailAddress={diverName}
                message="Enter the email address used for your USFA membership to sign on for the Cup"
                userSelectedCallback={userSelectedCallback}
                disableControls={false}
            />
            {diverFound && (
                <Container>
                    <Row>
                        <Col lg="3">
                            <FormGroup>
                                <Label>Diving from?</Label>
                            </FormGroup>
                        </Col>
                        <Col lg={{ size: 3 }}>
                            <FormGroup>
                                <select name="divingLocation" id="divingLocation">
                                    <option value="Sydney Metro</option>">Sydney Metro</option>
                                    <option value="Sydney South (Kurnell">Sydney South (Kurnell, Cronulla, Bundena)</option>
                                    <option value="Sydney Northen Beache">Sydney Northen Beaches</option>
                                    <option value="Royal National Park</">Royal National Park</option>
                                    <option value="Bully / Bellambi Area">Bully / Bellambi Area</option>
                                    <option value="Wollongong Area</opti">Wollongong Area</option>
                                    <option value="Port Kembla</option>">Port Kembla</option>
                                    <option value="Shellharbour</option>">Shellharbour</option>
                                    <option value="Norah Head</option>">Norah Head</option>
                                    <option value="Terrigal</option>">Terrigal</option>
                                    <option value="The Entrance</option>">The Entrance</option>
                                    <option value="Avoca - Box Head Area">Avoca - Box Head Area</option>
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={{ offset: 3, size: 6  }}>
                            <FormGroup>
                                <Input type="text" placeholder="Please enter Exact Headland" onChange={onHeadlandInputChange} required />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={{ offset: 3, size: 6 }} className="text-right">
                            <FormGroup>
                                <Button color="primary" onClick={signOn}>
                                    <FontAwesomeIcon icon={faSave} /> Sign On
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p>
                                <Alert color="primary">
                                    It is important you remember to sign off when you leave the water.<br />
                                    So the Cup organizers know you are safe!!!
                                </Alert>
                            </p>
                        </Col>
                    </Row>

                    {headlandNotSelelected &&
                        <Row>
                            <Col lg={{ offset: 3, size: 6 }}>
                                <Alert color="danger">
                                    Primary Diving location & Exact Headland are required
                                </Alert>
                            </Col>
                        </Row>
                    }
                </Container>
            )}



        </KingFishCupHeader>
    );
}

export const KingFishCupSignOff = (props) => {

    const [blockName, setBlockName] = useState("");
    const [diverName, setDiverName] = useState(props.emailAddress);
    const [diver, setDiver] = useState({ id: 0 });
    const [diverFound, setDiverFound] = useState(false)
    const [actionSaved, setActionSaved] = useState(false);

    const [selectedOption, setSelectedOption] = useState("");
    const [headlandName, setHeadlandName] = useState("");
    const [headlandNotSelelected, setHeadlandNotSelelected] = useState(false);

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    let userSelectedCallback = (diver) => {
        setDiver(diver);
        setDiverFound(true);
    };

    let signOff = function () {
        DataAPI.signOff({ diverId: diver.id }, actionCallBack);
    }

    let actionCallBack = function (data, callbackData) {
        setActionSaved(true);
    }

    return (
        <KingFishCupHeader
            headerMessage="Sign Off"
            actionSuccessful={actionSaved}
        >
            <Row>
                <Col>
                    <p>
                        <Alert color="primary">
                            When exiting the water; please Sign Off here<br />
                            Do this; so we know you are safe!!!
                        </Alert>
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>
                        <Alert color="primary">
                            It is also compulsory to sign off to avoid disqualification <br />
                            Remember; you can sign on / off multiple times during the day / weekend
                        </Alert>
                    </p>
                </Col>
            </Row>

            <KingfishCupFindDiver
                emailAddress={diverName}
                message="Enter the email address used for your USFA membership to sign on for the Cup"
                userSelectedCallback={userSelectedCallback}
                disableControls={false}
            />
            {diverFound && (
                <Container>

                    <Row>
                        <Col lg={{ offset: 3, size: 6 }} className="text-right">
                            <FormGroup>
                                <Button color="primary" onClick={signOff}>
                                    <FontAwesomeIcon icon={faSave} /> Sign Off
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>

                </Container>
            )}

        </KingFishCupHeader>
    );
}

export const KingFishCupSignWeightIn = (props) => {

    let { emailAddress } = useParams();

    const [blockName, setBlockName] = useState("");
    const [diverName, setDiverName] = useState(props.emailAddress);
    const [diver, setDiver] = useState({ id: 0 });
    const [diverFound, setDiverFound] = useState(false)
    const [actionSaved, setActionSaved] = useState(false);

    const [selectedOption, setSelectedOption] = useState("");
    const [headlandName, setHeadlandName] = useState("");
    const [headlandNotSelelected, setHeadlandNotSelelected] = useState(false);

    const [fishWeight, setFishWeight] = useState("");
    const [fishLengh, setFishLengh] = useState("");
    const [fishGirth, setFishGirth] = useState("");
    const [fishLocation, setFishLocation] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    let userSelectedCallback = (diver) => {
        Common.log("diverSet");
        Common.log(diver);

        setDiver(diver);
        setDiverFound(true);
    };

    let onChangeFishWeight = function (item, event) {
        setFishWeight(item.target.value);
    };

    let onChangeFishLength = function (item, event) {
        setFishLengh(item.target.value);
    };

    let onChangeFishGirth = function (item, event) {
        setFishGirth(item.target.value);
    };

    let onChangeFishLocation = function (item, event) {
        setFishLocation(item.target.value);
    };

    let addFish = function () {
        DataAPI.addWeightIn({ diverId: diver.id, weight: fishWeight, length: fishLengh, girth: fishGirth, location: fishLocation }, actionCallBack);
    }

    let actionCallBack = function (data, callbackData) {
        setActionSaved(true);
    }

    return (
        <KingFishCupHeader
            headerMessage="Weight In Fish"
            actionSuccessful={actionSaved}
        >
            <KingfishCupFindDiver
                emailAddress={emailAddress}
                message="Enter the email address used to register for the Cup"
                userSelectedCallback={userSelectedCallback}
            />
            {diverFound && (
                <Fragment>
                    <Row>
                        <Col lg="3">
                            <FormGroup>
                                <Input type="text" placeholder="Weight in grams" maxLength="5" value={fishWeight} onChange={onChangeFishWeight} />
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                                <Input type="text" placeholder="Length in cm" maxLength="3" value={fishLengh} onChange={onChangeFishLength} />
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                                <Input type="text" placeholder="Girth in cm" maxLength="3" value={fishGirth} onChange={onChangeFishGirth} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="9">
                            <FormGroup>
                                <Input type="text" placeholder="location" maxLength="100" value={fishLocation} onChange={onChangeFishLocation} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="9" className="text-right">
                            <FormGroup>
                                <Button color="primary" onClick={addFish}>
                                    <FontAwesomeIcon icon={faSave} /> Add Weight
                                </Button>
                            </FormGroup>

                        </Col>
                    </Row>
                </Fragment>
            )}

        </KingFishCupHeader>
    );
}

export const KingFishCupAddDiver = (props) => {

    const [blockName, setBlockName] = useState("");
    const [diverName, setDiverName] = useState(props.emailAddress);
    const [diver, setDiver] = useState({ id: 0 });
    const [diverFound, setDiverFound] = useState(false)
    const [actionSaved, setActionSaved] = useState(false);

    const [diverInputFirstName, setDiverInputFirstName] = useState("");
    const [diverInputLastName, setDiverInputLastName] = useState("");
    const [diverInputEmail, setDiverInputEmail] = useState("");
    const [diverInputPhoneNumber, setDiverInputPhone] = useState("");

    const [selectedOption, setSelectedOption] = useState("");
    const [headlandName, setHeadlandName] = useState("");
    const [headlandNotSelelected, setHeadlandNotSelelected] = useState(false);

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    let userSelectedCallback = (diver) => {
        setDiver(diver);
        setDiverFound(true);
    };

    let onFirstNameInputChange = function (item, event) {
        setDiverInputFirstName(item.target.value);
    };

    let onLastNameInputChange = function (item, event) {
        setDiverInputLastName(item.target.value);
    };

    let onEmailInputChange = function (item, event) {
        setDiverInputEmail(item.target.value);
    };

    let onPhoneInputChange = function (item, event) {
        setDiverInputPhone(item.target.value);
    };

    let addDiver = function () {
        DataAPI.addDiver({ firstName: diverInputFirstName, lastName: diverInputLastName, emailAddress: diverInputEmail, phoneNumber: diverInputPhoneNumber }, actionCallBack);
    }

    let actionCallBack = function (data, callbackData) {
        setActionSaved(true);
    }

    return (
        <KingFishCupHeader
            headerMessage="Register for the Kingfish Cup"
            actionSuccessful={actionSaved}
        >
            <Container fluid>
                <Row>
                    <Col lg="9">
                        <FormGroup>
                            <p>
                                Here you can manually add Divers who have just signed for an USFA Membership
                            </p>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>

            <KingfishCupFindDiver
                emailAddress={diverName}
                message="First Check if the Diver Exists"
                userSelectedCallback={userSelectedCallback}
            />

            <Container>
                <Row>
                    <Col lg="9">
                        <FormGroup>
                            <Label>First Name</Label>
                            <Input type="text" value={diverInputFirstName} onChange={onFirstNameInputChange} placeholder="First Name">
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Last Name</Label>
                            <Input type="text" value={diverInputLastName} onChange={onLastNameInputChange} placeholder="Last Name">
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Email Address</Label>
                            <Input type="text" value={diverInputEmail} onChange={onEmailInputChange} placeholder="Email Address">
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Phone Number</Label>
                            <Input type="text" value={diverInputPhoneNumber} onChange={onPhoneInputChange} placeholder="Phone Number">
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="9" className="text-right">
                        <FormGroup>
                            <Button color="primary" onClick={addDiver} disabled={props.disableControls}>
                                <FontAwesomeIcon icon={faSave} /> Save Details
                            </Button>{' '}
                        </FormGroup>
                    </Col>
                </Row>
            </Container>

        </KingFishCupHeader>
    );
}

export const KingFishCupHeader = (props) => {

    let logoIconPath = require("./../Resources/Images/LogoCircleWhite.jpg");

    return (
        <Block name="operations" isAlt={false}>
            <Row>
                <Col lg="4">
                    <FormGroup>
                        <img src={logoIconPath} className="menu-logo-large img-fluid" style={{ height: 300 }} />
                    </FormGroup>
                </Col>
                <Col lg="8">
                    <Container>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <h1>{props.headerMessage}</h1>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {props.children}
                            </Col>
                        </Row>
                        {props.actionSuccessful &&
                            <Row>
                            <Col lg={{ size: 9 }}>
                                    <FormGroup>
                                        <Alert color="success">
                                            Operation successfully Saved
                                        </Alert>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col className="text-right" lg={{ size: 9 }}>
                                 <Link to="/" className="btn btn-success"><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back to Kingfish Cup Site</Link>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Block>
    );
}

const KingfishCupFindDiver = (props) => {

    const [diverName, setDiverName] = useState(props.emailAddress);
    const [diver, setDiver] = useState(null);
    const [diverSearched, setDiverSearched] = useState(false);
    const [diverFound, setDiverFound] = useState(false);
    const [registrationOpen, setRegistrationOpen] = useState(false);

    useEffect(() => {
        if (diverName != null) {
            searchDiver();
        }
    }, []);

    let reset = function () {
        setDiverName("");
        setDiverSearched(false);
        setDiverFound(false);
    };

    let onSearchInputChange = function (item, event) {
        setDiverName(item.target.value);
    };

    let searchDiver = function () {
        DataAPI.searchDiver({ EmailAddress: diverName }, searchDiverCallback);
    };

    let searchDiverCallback = function (data, callbackData) {
        console.log(data);

        if ((data) && (data.length > 0)) {
            setDiver(data[0]);
            setDiverFound(true);
            props.userSelectedCallback(data[0]);
        }
        setDiverSearched(true);
    }

    return (
        <Container fluid>
            <Row>
                <Col lg="9">
                    <FormGroup>
                        {props.message}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="9">
                    <FormGroup>
                        <Input type="text" value={diverName} onChange={onSearchInputChange} placeholder="Enter Email Address" disabled={props.disableControls}>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="9" className="text-right">
                    <FormGroup>
                        <Button color="primary" onClick={searchDiver} disabled={props.disableControls}>
                            <FontAwesomeIcon icon={faSearch} /> Find Diver
                        </Button>{' '}
                        <Button color="danger" onClick={reset} disabled={props.disableControls}>
                            <FontAwesomeIcon icon={faTrash} /> 
                        </Button>
                    </FormGroup>
                </Col>
            </Row>

            {diverSearched && (
                <Fragment>
                    {diverFound && (
                        <Fragment>

                            <Row>
                                <Col lg="9">
                                    <FormGroup>
                                        <Alert color="primary">
                                            {diver.firstName} {diver.lastName} @ <b>{diver.emailAddress}</b>
                                        </Alert>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                            </Row>

                        </Fragment>
                    )}
                    {!diverFound &&
                        <Fragment>
                            <Row>
                                <Col lg="9">
                                    <Alert color="danger">Search Didn't find a diver by that email address</Alert>
                                </Col>
                            </Row>
                        </Fragment>
                    }
                </Fragment>
            )}

        </Container>
    );
}