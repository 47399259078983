import React, { Component } from 'react';
import { UncontrolledCarousel } from 'reactstrap';

import Common from '../../Shared/Common';


export class Caroursel extends Component {
    constructor(props) {
        super(props);
        Common.log('Component Init ->  Caroursel');

        this.state = {
            model: {
                images: props.carouselImages
            }
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps !== this.props) {
            this.setState({ model: { images: this.props.carouselImages } });
        }
    }

    render() {
        return (
            <UncontrolledCarousel items={this.state.model.images} Fluid />
        );
    }
}