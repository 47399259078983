import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import { Container, Row, Col } from 'reactstrap';

import './../Resources/css/block_top.css'
import Block from '../../../Controls/Scafolding/Block';
import { Button } from 'bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faFish } from '@fortawesome/free-solid-svg-icons';

const Top = (props) => {

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (

        <Block name="top" isAlt={true}>
            <Row>
                <Col lg="12" className="text-center top-dates">
                    Cup Dates Nov 9/10th
                </Col>
            </Row>

            <Row>
                {/*<Col lg="12" className="text-center top-register">*/}
                {/*    <Link className="" to="/Register"><FontAwesomeIcon icon={faFish} /> Register Now !!!</Link>*/}
                {/*</Col>*/}
            </Row>

            <Row className="top-inner-lg d-none d-sm-block">
                {/*<Col lg="12" className="text-center">*/}
                {/*    <Link className="btn btn-lg btn-primary" to="/Register"><FontAwesomeIcon icon={faFish} /> Register Now !!</Link>*/}
                {/*</Col>*/}

                <Col lg="12" className="text-center">
                    <Link className="btn btn-success" to="/SignOn"><FontAwesomeIcon icon="facebook" />Sign On</Link>&nbsp;
                    <Link className="btn btn-danger" to="/SignOff">Sign Off</Link>
                    <p>
                        In case of emergency or having issues with the online Sign On / Sign Off call this number: 0439 818 409 
                    </p>
                </Col>
            </Row>

            <Row className="top-inner-xs d-block d-sm-none">
                {/*<Col lg="12" className="text-center">*/}
                {/*    <Link className="btn btn-lg btn-primary" to="/Register"><FontAwesomeIcon icon={faFish} /> Register Now !!!</Link>*/}
                {/*</Col>*/}

                <Col lg="12" className="text-center">
                    <Link className="btn btn-success" to="/SignOn"><FontAwesomeIcon icon="facebook" />Sign On</Link>&nbsp;
                    <Link className="btn btn-danger" to="/SignOff">Sign Off</Link>
                    <p>
                        In case of emergency or having issues with the online Sign On / Sign Off call this number: 0439 818 409 
                    </p>
                </Col>
            </Row>

            <Row className="top-inner-bottom">
            </Row>
        </Block>

    );
};
export default Top;


