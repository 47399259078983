import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

import { configureAnchors } from 'react-scrollable-anchor'
import { goToAnchor } from 'react-scrollable-anchor'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import Common from '../Shared/Common';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            menu: props.menu,
            scrollClass: 'scroll-top',
            offset : props.offset ?? 75
        };

        this.toggleNavbar = this.toggleNavbar.bind(this);

        //Title & Icon
        document.title = props.menu.text;
        this.changeFavicon(props.menu.icon);
    }

    componentDidMount() {
        configureAnchors({ offset: -60, scrollDuration: 400 });

        //Track Top
        window.onscroll = () => {
            if (window.pageYOffset === 0) {
                this.setState({ scrollClass: "scroll-top" });
            } else {
                if (this.state.scrollClass !== "scroll-non-top")
                    this.setState({ scrollClass: "scroll-non-top" });
            }
        };

    }

    changeFavicon = (src) => {
        var link = document.createElement('link'),
            oldLink = document.getElementById('dynamic-favicon');
        link.id = 'dynamic-favicon';
        link.rel = 'shortcut icon';
        link.href = src;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    closeToggleNavbar() {
        this.setState({
            collapsed: true
        });
    }

    menuClick = () => {
        this.closeToggleNavbar();
    }

    render() {
        return (
            <Navbar className={`navbar-expand-sm navbar-toggleable-sm menu-navbar Menu sticky-top darkBkg ${this.state.scrollClass}`} >
                <Container id="navbar">

                    {this.state.menu.hasLogo && <div className="navbar-brand-logo navbar-brand-logo-large"> <NavbarBrand tag={Link} to="/#top" className="d-none d-sm-block menu-brand" onClick={this.menuClick.bind(this, "/#top")}><img src={this.state.menu.logoUrl} className="menu-logo-large img-fluid" /></NavbarBrand></div>}
                    {this.state.menu.hasLogo && <div className="navbar-brand-logo  navbar-brand-logo-small"> <NavbarBrand tag={Link} to="/#top" className="d-block d-sm-none menu-brand" onClick={this.menuClick.bind(this, "/#top")}><img src={this.state.menu.logoUrl} className="menu-logo-small img-fluid" /></NavbarBrand></div>}

                    {!this.state.menu.hasLogo &&
                        <div>
                            <AnchorLink href="#top" onClick={this.menuClick.bind(this)} className="menu-brand menu-item nav-link" offset={this.state.offset}>
                                <div className="d-none d-sm-block">{this.state.menu.text}</div>
                                <div className="d-block d-sm-none">{this.state.menu.textSmallDisplay}</div>
                            </AnchorLink>
                        </div>}

                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2 menu-toggler navbar-btn-toggler" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse menu-collapse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow menu-parent-item">
                            {this.state.menu.items.map((item, index) =>
                                <Fragment key={index}>
                                    <AnchorLink href={item.linkUrl} onClick={this.menuClick.bind(this)} className="menu-item nav-link" offset={this.state.offset}>{item.linkLabel}</AnchorLink>
                                    {this.state.menu.insertBars && (!item.isClosing) && <span className="nav-link"> | </span>}
                                </Fragment>
                            )}
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}
