import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';

export const ContentWrapper = props =>
    <div className="content-wrapper">
        {props.children}
    </div>;

export const ContentAbout0 = props =>
    <Fragment>
        <p>
           <b>birdsmouth</b> / noun / (arch.)
        </p>
        <p>
            bəːd&delta;/maʊθ/
        </p>
        <p>
            <i>
                An interior angle of notch cut across the end of a rafter providing a solid support base in transferring roof loads. <br />
                The Birdsmouth provides strength, integrity and support for the wall and roof system.  
            </i>    
        </p>
    </Fragment>;

export const ContentAbout1 = props =>
    <Fragment>
        <p>
            Birdsmouth PM was established in 2020 as a boutique project management firm focusing on ensuring every client receives a bespoke approach to its project needs.
        </p>
    </Fragment>;

export const ContentAbout2 = props =>
    <Fragment>
        <p>
            Our no - nonsense approach, dedication and commitment ensures our clients’ interests are in safe hands.We approach all matters with integrity and pride ourselves on the <span className="highlight">strength</span> of the relationships built throughout the development phase between our valued clients, consultants, and head contractors alike.
        </p>
    </Fragment>;

export const ContentAbout3 = props =>
    <Fragment>
        <p>
            Our project and development management services are delivered in a structured, organised and methodical manner allowing efficiency, transparency and <span className="highlight">integrity</span> throughout the development lifecycle.
        </p>
    </Fragment>;

export const ContentAbout4 = props =>
    <Fragment>
        <p>
            At Birdsmouth PM, we recognise that no two projects are ever the same. That is why we <span className="highlight">support</span> every project with tailored solutions that best suit client and project requirements
        </p>
    </Fragment>;



export const ContentWhoAmI1 = props =>
    <Fragment>
        <p>
            Michael Kondilios is an accomplished project manager with 20 years’ experience in the construction industry, including extensive involvement in the aged care, residential and education sector.
        </p>
    </Fragment>;

export const ContentWhoAmI2 = props =>
    <Fragment>
        <p>
            A result driven and hands - on construction and development individual, Michael is well versed in many aspects of project management driving triple bottom line of design, cost, and time element to ensure the valued client achieves its desired project outcomes.
        </p>
    </Fragment>;

export const ContentWhoAmI3 = props =>
    <Fragment>
        <p>
            Having worked as a construction project manager, in -house project manager, and for client - side project management firms, Michael understands the project deliverables from all points of view. He looks forward in sharing his experience and ability in delivering projects with the dedication and commitment needed for each project and expected by each client.
        </p>
    </Fragment>;

export const ContentWhoAmI4 = props =>
    <Fragment>
        <p>
            He looks forward in sharing his experience and ability in delivering projects with the dedication and commitment needed for each project and expected by each client.
        </p>
    </Fragment>;

export const ContentWhoAmI5 = props =>
    <Fragment>
            <Col>
                The following list outlines areas of project management that Michael has had continued involvement with:
                <Container>
                    <Row>
                        <Col lg={{ size: 5, offset: 1 }}>
                            <ul>
                                <li>AS 4902 - 2000 General Conditions of Contract for Design and Construct</li>
                                <li>AS 4000 - 1997 General Conditions of Contract</li>
                                <li>Early Contractor Involvement(ECI)</li>
                                <li>Tender preparation, invitation, negotiation, recommendation, and appointment</li>
                                <li>Project Control Group(PCG) chair, agenda, and minuting</li>
                                </ul>
                        </Col>
                        <Col lg="5">
                            <ul>
                                <li>Coordination of FFE</li>
                                <li>Project financial reporting, budgeting, and negotiating</li>
                                <li>Local government consent authorities</li>
                                <li>Joint Regional Planning Panels</li>
                                <li>Land Registry Services</li>
                                <li>Utility providers</li>
                                <li>Mine Subsidence Advisory Board</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Col>
    </Fragment>;



export const ContentWorkMap1 = props =>
    <Fragment>
        <p className="block-header-title-text">
            Birdsmouth<span className="block-header-title-text-highlight">pm</span> services the Sydney metropolitan and regional areas of NSW with the same <span className="block-header-title-text-highlight">focus</span> -  <span className="block-header-title-text-highlight">precision</span> - <span className="block-header-title-text-highlight">delivery</span> toward each project. 
        </p>
    </Fragment>;


export const ContentWorkMap2 = props =>
    <Fragment>
        <p className="block-header-title-text">
            Birdsmouth<span className="block-header-title-text-highlight">pm</span> values the importance of every project development and immerses itself in each project, irrespective of size, scale or volume of work required.
        </p>
    </Fragment>;


export const ContentWorkMap3 = props =>
    <Fragment>
        <p className="block-header-title-text">
           Whether it be project feasibility studies, written advices, refurbishment works, or new build works, Birdsmouth<span className="block-header-title-text-highlight">pm</span> is focused and ready to deliver for its valued clients.
        </p>
    </Fragment>;


export const ContentWorkMap4 = props =>
    <Fragment>
        <p className="block-header-title-text">
            Birdsmouth<span className="block-header-title-text-highlight">pm</span> project values range between $1.0m and $40.0m 
        </p>
    </Fragment>;


export const ContentWorkMap5 = props =>
    <Fragment>
        <p className="block-header-title-text">
            An interior angle of notch cut across the end of a rafter providing a solid support base in transferring roof loads. The birdsmouth provides strength, integrity and support for the wall system, the value on which Birdsmouth<span className="block-header-title-text-highlight">pm</span> is founded.
        </p>
    </Fragment>;






