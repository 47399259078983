import React, { Fragment, useEffect, useState, Component } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Row, Col, FormGroup, Button } from 'reactstrap';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';
import DataAPI from '../Shared/DataAPI';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import '../Resources/css/site.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const KingFishCupManage = (props) =>{

    const [blockName, setBlockName] = useState("");
    const [val, setVal] = useState();
    const [filterText, setFilterText] = useState("");
    const [members, setMembers] = useState([]);

    const gridColumns = [
        { field: "id", hide : true },
        { field: "firstName", filter : true, width:120 },
        { field: "lastName", filter: true, width: 120  },
        { field: "emailAddress", filter: true, width: 250, hide: true },
        { field: "phone", width: 120 },
        { field: "signedOff", headerName: 'Signed Off', width: 120 },
        {
            field: 'events',
            width: 280,
            headerName: 'Sign Ins',
            cellRenderer: InlineCellRenderer,
            cellRendererParams: [{
                renderFunction: function (item) { return format("<div><b>{0}</b> - {1} @ {2}</div>", [new Date(item.changeLog.createdDate).toLocaleString(), item.eventName, item.location]); }
            }],
        },
        {
            field: 'fishes',
            width: 280,
            headerName: 'Captures',
            cellRenderer: InlineCellRenderer,
            cellRendererParams: [{
                renderFunction: function (item) { return format("<div><b>{0}</b> - {1} @ {2}</div>", [new Date(item.changeLog.createdDate).toDateString(), formatWeight(item.weight), item.location]); }
            }],
        },
        {
            field: 'emailAddress',
            width: 150,
            headerName: 'Add Weight In',
            cellRenderer: LinkCellRenderer,
            cellRendererParams: [{
                renderFunction: function (item) { return format("<div><b>{0}</b> - {1} @ {2}</div>", [new Date(item.changeLog.createdDate).toDateString(), formatWeight(item.weight), item.location]); }
            }],
        }
    ];

    let getDiversCallback = (data) => {
        Common.log(data);
        setMembers(data);
    };

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);

        DataAPI.getDivers({}, getDiversCallback);
    }, [setMembers]);

    function format(inputString, params) {
        if ((params != null) && (params.length > 0)) {
            var i = params.length;
            while (i--) {
                inputString = inputString.replace(new RegExp('\\{' + i + '\\}', 'gm'), params[i]);
            }
        }
        return inputString;
    };

    function formatWeight(weight) {

        if (weight.length == 4) {
            var kg = weight.substring(0, 1);
            var grms = weight.substring(1, 3);

            return format("{0},{1} kg", [kg, grms]);
        }
        if (weight.length == 5) {
            var kg = weight.substring(0, 2);
            var grms = weight.substring(2, 3);

            return format("{0},{1} kg", [kg, grms]);
        }
        return format("{0} kg", [weight]);
    }

    let onSearchInputChange = function (item, event) {
        setFilterText(item.target.value);
    }

    let clearFilters = function () {
        setFilterText("");
        setVal("");
    }


    let onRowDataChanged = (rowDataChanged) => {
        rowDataChanged.api.resetRowHeights();
    };

   let getRowHeight = (rowHeight) => {
       let rows = rowHeight.data.events.length;
       if (rows == 1)
           rows = 2;

        if (rows > 0)
            return 20 * rows;

        return null;
    }

    return (
        <Block name="operations" isAlt={false}>

            <TextHeader text={
                <Fragment>
                    Manage Divers
                </Fragment>
            } />

            <Row>

                <Col lg="4">
                    <FormGroup>
                        <label>Find a Diver </label>{' '}
                        <input type="text" onChange={onSearchInputChange} value={val} />
                    </FormGroup>
                </Col>
                <Col lg="2">
                    <FormGroup>
                        <Button className="form-control" color="danger" onClick={clearFilters} size="sm">
                            <FontAwesomeIcon icon={faTrash} /> Clear Filters
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div id="gridDivers" className="ag-theme-alpine" style={{ height: 750 }}>
                        <AgGridReact
                            columnDefs={gridColumns}
                            rowData={members}
                            onRowDataChanged={onRowDataChanged}
                            getRowHeight={getRowHeight}
                            cacheQuickFilter={true}
                            quickFilterText={filterText}
                        />
                    </div>
                </Col>
            </Row>

        </Block>
    );
}
export default KingFishCupManage;


class InlineCellRenderer {
    // init method gets the details of the cell to be renderer
    init(params) {

        let innerHtml = "";
        params.value.forEach(function (item) { innerHtml += params[0].renderFunction(item);});

        this.eGui = document.createElement('div');
        this.eGui.className = "dive-events";
        this.eGui.innerHTML = innerHtml;
    }

    getGui() {
        return this.eGui;
    }

    refresh(params) {
        return false;
    }
}


class LinkCellRenderer {
    // init method gets the details of the cell to be renderer
    init(params) {

        this.eGui = document.createElement('a');
        this.eGui.setAttribute("href", "/AddWeightIn/" + params.value);
        this.eGui.setAttribute("target", "_blank");
        this.eGui.innerText = params.value;
        this.eGui.className = "dive-events";
    }

    getGui() {
        return this.eGui;
    }

    refresh(params) {
        return false;
    }
}
