import React, { Fragment, useEffect, useState } from 'react';
import './../Resources/css/block_pricing.css'
import { Container, Row, Col } from 'reactstrap';

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';
import { ContentPriceTheLot, ContentPriceVeg } from './_ContentBlocks';

const Pricing = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="price">

            <TextHeader text="Price guide" />

            <TextTitle text={
                <Fragment>
                    <p>To disscus dietary requirements and / or options on the indigrients; please <a href="#contactUs">contact us below.</a></p>
                    <p>We can tailor a qoute and disscus multiple paella options</p>
                </Fragment>
            } />

            <Row className="price-list">

                <Col lg="6" className="price-table">
                    <ContentPriceTheLot />
                </Col>

                <Col lg="6" className="price-table">
                    <ContentPriceVeg />
                </Col>

            </Row>

        </Block>
    );
};

export default Pricing;
