import React from 'react';
import { Row, Col } from 'reactstrap';

import './../Resources/css/block_top.css'
import Block from '../../../Controls/Scafolding/Block';

const Top = (props) => {

    return (
        <Block name="top" isAlt={true}>

            <Row className="top-Inner no-gutters">
                <Col lg="5">
                </Col>
                <Col lg="7">
                    <div className="top-content">
                        <h1>Welcome to Paella King</h1>
                        <h2>Your first stop to the best chorizo and seafood paella in Sydney</h2>
                    </div>
                </Col>
            </Row>

        </Block>
    );
};
export default Top;
