import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import './../Resources/css/block_about.css';

import { Row, Col } from 'reactstrap';
import { ContentAbout0, ContentAbout1, ContentAbout2, ContentAbout3, ContentAbout4 } from './_ContentBlocks';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';

const About = (props) =>{

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="about" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    about
                </Fragment>
            } />

            <Row>
                <Col lg={{ site: 10, offset: 1 }} className="definition">
                    <ContentAbout0 />
                </Col>
            </Row>

            <Row>

                <Col lg="11" className="parragraphs">
                    <ContentAbout1 />
                    <ContentAbout2 />
                    <ContentAbout3 />
                    <ContentAbout4 />
                </Col>
            </Row>
        </Block>
    );
}
export default About;
