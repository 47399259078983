import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import '../../Resources/Css/gallery.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Common from '../../Shared/Common';


export class Gallery extends Component {

    constructor(props) {
        super(props);
        Common.traceInit('Photo Gallery');

        this.state = {
            model: {
                effect: props.effect ?? 'black-and-white',
                imagesLeft: props.imagesLeft,
                imagesRight: props.imagesRight,
                placeholderSrc: props.placeholderSrc 
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            Common.log("Gallery Did Update");
            Common.log(this.props);
            this.setState({
                model: {
                    imagesLeft: this.props.imagesLeft,
                    imagesRight: this.props.imagesRight
                }
            });
        }
    }

    render() {
        return (
            <Fragment>
                
                <Row>
                    {this.state.model.imagesLeft.map((photo, index) =>
                        <Col className="polaroid-images polaroid-images-small info" lg="3" key={index}>
                            <a href="#"><LazyLoadImage src={photo.src} className="gallery-image img-fluid" effect="black-and-white" /></a>
                        </Col>
                    )}

                    {this.state.model.imagesRight.map((photo, index) =>
                        <Col className="polaroid-images polaroid-images-large info" lg="4" key={index}>
                            <a href="#">
                                <LazyLoadImage src={photo.src} className="gallery-image img-fluid" effect="blur" />
                            </a>
                        </Col>
                    )}

                </Row>

            </Fragment>
        );
    }
}
