import React, { Component, Fragment } from 'react';

//import ReactGA from 'react-ga';

import './Resources/css/site.css';

import { Container } from 'reactstrap';
import Top from './Blocks/Top';
import About from './Blocks/About';
import MeetTheDirector from './Blocks/MeetTheDirector';
import Projects from './Blocks/Projects';
import ImageInsert from './Blocks/ImageInsert';
import Expertise  from './Blocks/expertise';
import LocationAndContact from './Blocks/LocationAndContact ';
import Footer  from './Blocks/Footer';
import AppWrapper from '../../Controls/Scafolding/AppWrapper';
import { NavMenu } from '../../Controls/NavMenu';
import CommonAPI from '../../Shared/CommonAPI';
import Common from '../../Shared/Common';

export class BirdsMouth extends Component {

    constructor(props) {
        super(props);
        Common.log('Component Init -> /BMStart');

        let logoImagePath = require("./Resources/Images/logoTransparent.png");
        let logoIconPath = require("./Resources/Images/logo.ico");

        this.state = {
            brandCode: 'BM',

            menu: {

                hasLogo: true,
                logoUrl: logoImagePath,
                logoWidth: '250px',

                styles: {},
                insertBars: false,

                text: 'Birdsmouth PM Pty Ltd',
                icon: logoIconPath,

                items: [
                    { linkLabel: 'about', linkUrl: '#about' },
                    { linkLabel: 'projects', linkUrl: '#projects' },
                    { linkLabel: 'meet the director', linkUrl: '#meetTheDirector' },
                    { linkLabel: 'expertise', linkUrl: '#expertise' },
                    { linkLabel: 'locations', linkUrl: '#locationsAndContact'},
                    { linkLabel: 'contact', linkUrl: '#locationsAndContact', isClosing: true  }
                ],
            }
        };
    }

    //componentDidMount() {
    //    this.loadBrandContent();
    //}

    //#region Brand Managmenet
     
    //loadBrandContent = () => {
    //    //ReactGA.initialize('UA-192471751-1');
    //    //ReactGA.pageview(window.location.pathname + window.location.search);
    //}

    //#endregion

    render() {
        return (
            <AppWrapper wrapClass="bm">
                <Top />
                <NavMenu menu={this.state.menu} offset="50" addClass="Menu sticky-top" />

                <About />
                <Projects /> 
                <MeetTheDirector />

                <ImageInsert addClass="walshBay" />
                <Expertise />

                <ImageInsert addClass="courtyard" />
                <LocationAndContact />
                <Footer />

            </AppWrapper>
        );
    }
}
