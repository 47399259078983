import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';
import { Row, Col } from 'reactstrap';

const Footer = (props) => {

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="footer" isAlt={true}>
            <Row>
                <Col lg="12" className="footer-message">
                    &#169; 2021 Birdsmouth PM Pty Ltd
                </Col>
            </Row>
        </Block>
    );
}
export default Footer;