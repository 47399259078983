import React, { Fragment, useEffect, useState } from 'react';
import Block from '../../../Controls/Scafolding/Block';
import Common from '../../../Shared/Common';

import './../Resources/css/block_expertise.css';

import { Row, Col } from 'reactstrap';

import iconDesign from '../Resources/Images/expertise-design.svg';
import iconApprove from '../Resources/Images/expertise-approve.svg';
import icondDeliver from '../Resources/Images/expertise-deliver.svg';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

const Expertise = (props) => {

    const [blockName, setBlockName] = useState("");
    const [showDesign, setShowDesign] = useState(true);
    const [showApprove, setShowApprove] = useState(true);
    const [showDeliver, setShowDeliver] = useState(true);

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    const showItemDesign = () => {
        setShowDesign(!showDesign);
        setShowApprove(true);
        setShowDeliver(true);
    }

    const showItemApprove = () => {
        setShowDesign(true);
        setShowApprove(!showApprove);
        setShowDeliver(true);
    }

    const showItemDeliver = () => {
        setShowDesign(true);
        setShowApprove(true);
        setShowDeliver(!showDeliver);
    }

    return (
        <Block name="expertise" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    expertise
                </Fragment>
            } />

            <Row className="expertise-header">
                <Col>
                    Birdsmouth PM expertise in project and development management services include:
                </Col>
            </Row>

            <Row>
                <Col lg="4" className="expertise-item" onClick={showItemDesign} onMouseEnter={showItemDesign} onMouseLeave={showItemDesign}>
                    <div className="expertise-item-header">
                        design
                    </div>
                    {showDesign && <img src={iconDesign} className="img-fluid expertise-item-image" />}
                    {!showDesign && <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <p className="lead">
                                feasibility studies<br />
                                project briefing and development<br />
                                stakeholder management<br />
                                consultant briefs, tender and award<br />
                                development and construction financials<br />
                                value-add, value-manage, value-engineer<br />
                                continuous design development <br />
                                FF&E co-ordination
                            </p>
                        </div>
                    </div>}
                </Col>

                <Col lg="4" className="expertise-item" onClick={showItemApprove} onMouseEnter={showItemApprove} onMouseLeave={showItemApprove}>
                    <div className="expertise-item-header">
                        approve
                    </div>
                    {showApprove && <img src={iconApprove} className="img-fluid expertise-item-image" />}
                    {!showApprove && <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <p className="lead">
                                statutory authority, planning and approvals process<br />
                                LGA, JRPP, TfNSW, LRS, <br />
                                Mine Subsidence Board, and utility providers <br />
                                contract drafting and negotiations<br />
                                delivery method and modelling AS 4902-2000, AS 4000, GC21<br />
                                Project Control Group (PCG), chair, agenda, minuting
                            </p>
                        </div>
                    </div>}
                </Col>

                <Col lg="4" className="expertise-item" onClick={showItemDeliver} onMouseEnter={showItemDeliver} onMouseLeave={showItemDeliver}>
                    <div className="expertise-item-header">
                        deliver
                    </div>
                    {showDeliver && <img src={icondDeliver} className="img-fluid expertise-item-image" />}
                    {!showDeliver && <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <p className="lead">
                                project management and superintendent services<br />
                                Early Contractor Involvement (ECI)<br />
                                programming and financial control<br />
                                head contract – tender documentation, packaging, procurement and award<br />
                                construction delivery<br />
                                post-construction delivery services
                            </p>
                        </div>
                    </div>}
                </Col>

            </Row>

        </Block>
    );
}
export default Expertise;