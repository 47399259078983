import React, { Fragment, useEffect, useState } from 'react';

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

import { Row, Col } from 'reactstrap';

const StatementP1 = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="statementp1" isAlt={true}>

            <Row className="top-Inner no-gutters">
                <Col lg={{ size: 4, offset: 1 }}>

                    <TextHeader text={
                        <Fragment>
                            Ethical Behaviour
                        </Fragment>
                    } />

                    <p>
                        ASA believes that when you put on a wetsuit you are wearing a uniform that sees you representing all the spearo tribe, so your actions and behaviour, in and out of the water,  reflect back on us all.
                    </p>
                    <p>
                        ASA’s passionate teachings of respect and maintaining integrity with the ocean and its other users leave a lasting impression.
                    </p>
                </Col>

                <Col lg={{ size: 4, offset: 1 }}>

                    <TextHeader text={
                        <Fragment>
                            Spearfishing Safely
                        </Fragment>
                    } />

                    <p>
                        With over 7 decades of experience Andrew and Simon have witnessed and experienced much in the water.
                    </p>
                    <p>
                        They are highly skilled instructors who have introduced thousands of people, all ages and shapes and sizes, safely into the marine world. Our mantra to students “One up one down”
                    </p>
                </Col>
            </Row>

        </Block>
    );
};

export default StatementP1;
