import React, { Fragment, useEffect, useState } from 'react';

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

import { Row, Col } from 'reactstrap';
import { ContentCourse2DayWorkshop, ContentCourse2DayWorkshopExtended, ContentCourseDiveKids, ContentCourseDiveKidsExtended, ContentCourseHunter, ContentCourseHunterExtended, ContentCourseIntroduction, ContentCourseIntroductionExtended, ContentCourseLearning, ContentCourseLearningExtended } from './_ContentBlocks';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBook } from '@fortawesome/free-solid-svg-icons'


const Courses = (props) => {
    const [blockName, setBlockName] = useState("");
    const [isCourseSelected, setIsCourseSelected] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    const selectCourse = (courseName) => {
        setIsCourseSelected(true);
        setSelectedCourse(courseName);
    };

    function hideFullCourseInfo() {
        setIsCourseSelected(false);
        setSelectedCourse("");
    };

    return (
        <Block name="courses" isAlt={false} isFluid={true}>


            {!isCourseSelected ?
                <Fragment>

                    <TextHeader text={
                        <Fragment>
                            Chosing the right ASA Course for you
                        </Fragment>
                    } />

                    <TextTitle text={
                        <Fragment>
                            Australian Spearfishing Academy provides a range of workshops that cater for every level of
                            spearfisher or skindiver.
                        </Fragment>
                    } />

                    <Row>

                        <CourseWrapper 
                            course={<ContentCourseIntroduction />}
                            courseName='Introduction' selectCourseHanlder={selectCourse} addOffset='1' />

                        <CourseWrapper
                            course={<ContentCourseLearning />}
                            courseName="Learning" selectCourseHanlder={selectCourse} />

                        <CourseWrapper
                            course={<ContentCourse2DayWorkshop />}
                            courseName="2Day" selectCourseHanlder={selectCourse} />

                        <CourseWrapper
                            course={<ContentCourseHunter />}
                            courseName="Hunter" selectCourseHanlder={selectCourse} />

                        <CourseWrapper
                            courseName="DiveKids" course={<ContentCourseDiveKids />}
                            selectCourseHanlder={selectCourse} />

                    </Row>

                </Fragment>
                :

                <Row>
                    {selectedCourse == "Introduction" &&
                        <CourseFullInfoWrapper course={<ContentCourseIntroductionExtended />} />
                    }

                    {selectedCourse == "Learning" &&
                        <CourseFullInfoWrapper course={<ContentCourseLearningExtended /> } />
                    }

                    {selectedCourse == "2Day" &&
                        <CourseFullInfoWrapper course={<ContentCourse2DayWorkshopExtended />} />
                    }

                    {selectedCourse == "Hunter" &&
                        <CourseFullInfoWrapper course={<ContentCourseHunterExtended />} />
                    }

                    {selectedCourse == "DiveKids" &&
                        <CourseFullInfoWrapper course={<ContentCourseDiveKidsExtended />} />
                    }
                </Row>
            }
            {!isCourseSelected ?
                <Row>

                    <CourseButtonsWrapper
                        courseName='Introduction' addOffset='1'
                        isCourseSelected={isCourseSelected}
                        selectCourseHanlder={selectCourse} hideCourseHanlder={hideFullCourseInfo} />

                    <CourseButtonsWrapper
                        courseName="Learning"
                        isCourseSelected={isCourseSelected}
                        selectCourseHanlder={selectCourse} hideCourseHanlder={hideFullCourseInfo} />

                    <CourseButtonsWrapper
                        courseName="2Day"
                        isCourseSelected={isCourseSelected}
                        selectCourseHanlder={selectCourse} hideCourseHanlder={hideFullCourseInfo} />

                    <CourseButtonsWrapper
                        courseName="Hunter"
                        isCourseSelected={isCourseSelected}
                        selectCourseHanlder={selectCourse} hideCourseHanlder={hideFullCourseInfo} />

                    <CourseButtonsWrapper
                        courseName="DiveKids"
                        isCourseSelected={isCourseSelected}
                        selectCourseHanlder={selectCourse} hideCourseHanlder={hideFullCourseInfo} />
                </Row>
                :
                <Row>
                    <CourseHideDetailsButon
                        hideCourseHanlder={hideFullCourseInfo} />
                </Row>
            }

        </Block>
    );
};

export default Courses;

const CourseWrapper = (props) => {

    return (
        <Col lg={{ size: 2, offset: props.addOffset }} sm={{ size: 12 }} >
            {props.course}
            <p className="d-xl-none text-center">
                <Button className="btn-outline-dark" onClick={() => props.selectCourseHanlder(props.courseName)}>Full Info <FontAwesomeIcon icon={faArrowRight} /></Button>
            </p>
        </Col>
    );
};

const CourseFullInfoWrapper = (props) => {

    return (
        <Col lg={{ size: 10, offset: 1 }} sm={{ size: 12 }} >
            {props.course}
        </Col>
    );
};

const CourseButtonsWrapper = (props) => {

    return (
        <Col lg={{ size: 2, offset: props.addOffset }} className="text-center d-none d-md-block d-xl-block" >

            <p className="course-item-button">
                <Button className="btn-outline-dark" onClick={() => props.selectCourseHanlder(props.courseName)}>Full Info <FontAwesomeIcon icon={faArrowRight} /></Button>
            </p>

        </Col>
    );
};

const CourseHideDetailsButon = (props) => {
    return (
        <Col lg={{ size: 10, offset: 1 }} sm={{ size: 12 }} className="text-center" >
            <p className="course-item-button">
                <Button className="btn-outline-dark" onClick={() => props.hideCourseHanlder()}>Back to Courses  <FontAwesomeIcon icon={faBook} /></Button>
            </p>
        </Col>
    );
};