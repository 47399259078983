import React, { useEffect } from 'react';

import Common from './../Shared/Common';
import { Row, Col } from 'reactstrap';
import img from '../Resources/Images/construction.gif';

export const SiteNotAvailable = (props) => {

    useEffect(() => {
        Common.traceInit("SiteNotAvailable");
    });

    return (
        <Row>
            <Col lg="12" className="text-center">
                <img src={img} className="img-fluid" />
            </Col>
        </Row>
    );
};