import React, { Fragment, useEffect, useState } from 'react';
import './../Resources/css/About.css'

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import IconWrapper from '../../../Controls/Digital/IconWrapper';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';

import { Row, Col } from 'reactstrap';

const About = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="about" isAlt={false}>

            <TextHeader text={
                <Fragment>
                    About <strong>ASA</strong>
                </Fragment>
            } />

            <Row className="top-Inner no-gutters">
                <Col lg={{ size: 8, offset: 2 }}>
                    <p>
                        Australian Spearfishing Academy came about in 2007 from the desire of Simon Trippe and Andrew Harvey to educate the next generation of spearfishers on ethics and safety.
                    </p>
                    <p>
                        Their view is that when spearfishing is practiced in a wise and ethical way it is a most enduring form of fishing in regard to selectivity, bycatch, and habitat loss.
                    </p>
                </Col>               
            </Row>

            <TextHeader text={
                <Fragment>
                    Our Mission
                </Fragment>
            } />

            <Row className="top-Inner no-gutters">
                <Col lg={{ size: 8, offset: 2 }}>
                    <p>
                        We promote spearfishing as a healthy and meaningful pursuit that provides enjoyment, and food for the table.
                    </p>
                    <p>
                        By providing our insights, hands on training and bespoke learning spearfishing can be embraced by anyone.
                    </p>
                    <p>
                        Dive with our passion for spearfishing.
                    </p>
                </Col>
            </Row>

        </Block>
    );
};

export default About;
