import React, { Fragment, useEffect, useState } from 'react';

import { Row, Col } from 'reactstrap';

import Block from '../../../Controls/Scafolding/Block';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';
import { Caroursel } from '../../../Controls/Digital/Carousel';
import { ContentEvents } from './_ContentBlocks';

const Events = (props) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(props.images);
    });

    return (
        <Block name="events">

            <TextHeader text={
                <Fragment>
                    <strong>P</strong>aella <strong>K</strong>ing for your <strong>E</strong>vents
                </Fragment>
            } />

            <Row>
                <Col lg={{ size: 5, offset: 1 }}>
                    <Caroursel carouselImages={images} />
                </Col>

                <Col lg="6">
                    <ContentEvents />
                </Col>

            </Row>

        </Block>
    );
};

export default Events;
