import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { TextHeader } from '../../../Controls/Digital/TextHeaders';

import imgSimon from './../Resources/Images/HeadShots/Simon-1.jpeg'
import imgAndrew from './../Resources/Images/HeadShots/Andrew-1.png'

const Teachers = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="teachers" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    About your Teachers
                </Fragment>
            } />

            <Row>
                <Col lg={{ size: 3, offset:2 }} className="text-center">
                    <img src={imgSimon} className="img-fluid" />
                </Col>
                <Col lg={{ size: 4 }} className="align-middle light-fontface">
                    <h3>Simon Trippe</h3>
                    <p>Spearfishing is a big part of my life.</p>
                    <p>Having a father who lives and breathes spearfishing it was inevitable that I too became entranced with the underwater world that surrounds us. </p>
                    <p>Growing up I fortunately had many great diving mentors, I still do.</p>
                    <p>Becoming a student under Erez Beatus, Director of Apnea International, I became aware of the need to pass on safe practice in spearfishing. </p>
                </Col>
            </Row>

            <Row>
                <Col lg={{ size: 7, offset: 2 }} className="align-middle light-fontface">
                    <p>I get a tremendous buzz out of assisting people, sharing their excitement, and seeing them overcome hurdles becoming further engaged with the life aquatic.</p>
                </Col>
            </Row>

            <Row>
                <Col lg={{ size: 4, offset: 2 }} className="align-middle light-fontface">
                    <h3>Andrew Harvey</h3>
                    <p>I was introduced to spearfishing, while in high school diving Stradbroke Island and Noosa with one of Queensland’s pioneering spearfisher’s.</p>
                    <p>Moving to Sydney I was fortunate enough to come under the tutelage of some of Australia best blue water and competition spear fisherman.</p>
                    <p>I am always interested in the technique and innovation of equipment used chasing large Bluewater fish.</p>
                    <p>Sharing ideas, tactics and teaching others is extremely rewarding and enjoyable</p>
                </Col>
                <Col lg={{ size: 3 }} className="text-center">
                    <img src={imgAndrew} className="img-fluid" />
                </Col>
            </Row>

        </Block>
    );
};

export default Teachers;
