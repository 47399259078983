import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

export const TextHeader = (props) => {
    const [text, setText] = useState("");
    const [blocksLength, setBlocksLength] = useState("");

    useEffect(() => {
        setText(props.text);
        setBlocksLength(props.blocksLength);
    });

    return (
        <div className="text-header-box">
            <Row className="text-header">
                <Col lg="" className="text-header-text">
                    {text}
                </Col>
            </Row>
            <Row>
                <Col lg={blocksLength} className="text-header-underscore">
                </Col>
            </Row>
        </div>
    );
}

export const TextTitle = (props) => {
    const [text, setText] = useState("");

    useEffect(() => {
        setText(props.text);
    });

    return (
        <Row className="text-title">
            <Col lg="12" className="text-title-text">
                {text}
            </Col>
        </Row>
    );
}
