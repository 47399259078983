import Common from "./Common";

const CommonAPI = {

    expireCache: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Brand/ExpireCache", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    sendMessage: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Brand/contactUs", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    getTestimonials : async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Testimonial/getAll", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    getActiveTestimonials : async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Testimonial/getActive", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    getActiveTestimonialsTransformed : async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Testimonial/getActiveTransformedFromCache", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    getJobs : async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Jobs/GetActiveFromCache", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    getDivers: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Divers/GetAll", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    },

    searchDiver: async function (inputParmaeter, callbackMethod, callbackData, callbackErrorMethod) {
        Common.call("api/Divers/Search", inputParmaeter, callbackMethod, callbackData, callbackErrorMethod);
    }
};

export default CommonAPI;
